import React, { useState } from 'react';
import './PsychologistCard.css';
import AppointmentFlow from './AppointmentFlow'; // Görüşme planlama akışı bileşeni

function PsychologistCard({ imgSrc, name, specialty, bio, price }) {
  const [showFlow, setShowFlow] = useState(false); // Modal kontrolü

  const handlePlanMeeting = () => {
    if (!name) {
      console.error('Psikolog adı tanımsız!');
      return;
    }
    setShowFlow(true); // Görüşme planla modalını aç
  };

  const handleCloseFlow = () => {
    setShowFlow(false); // Modalı kapat
  };

  return (
    <div className="card-body">
      <div className="profile-image">
        <img src={imgSrc || 'https://via.placeholder.com/150'} alt={`Psikolog ${name}`} className="profile-img" />
      </div>

      <div className="profile-details">
        <h3>{name}</h3>
        <div className="specialties">
          {specialty.split(', ').map((spec, index) => (
            <span key={index} className="specialty-item">
              {spec}
            </span>
          ))}
        </div>
        <p className="bio">{bio}</p>
        <p className="price">Fiyat: {price}₺/40dk</p>
      </div>

      {/* Görüşme planla butonu */}
      <div className="action-buttons">
        <button className="bs4-btn bs4-btn-blue" onClick={handlePlanMeeting}>
          Görüşme Planla
        </button>
      </div>

      {/* Görüşme Planlama Flow Modalı */}
      {showFlow && (
        <AppointmentFlow
          name={name} // Psikolog adı burada gönderiliyor
          psychologistId={name} // Psikolog adı burada ID olarak da kullanılabilir
          onClose={handleCloseFlow}
        />
      )}
    </div>
  );
}

export default PsychologistCard;
