import React from 'react';
import './AppointmentModal.css'; // CSS dosyasını import edin

function AppointmentModal({ onClose }) {
  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Randevu Oluştur</h2>
        <form>
          <label>Kimin için psikolog arıyorsunuz?</label>
          <select>
            <option>Kendim için</option>
            <option>Çocuğum için</option>
            <option>Bir Yakınım için</option>
          </select>

          <label>Hangi konuda destek arıyorsunuz?</label>
          <input type="text" placeholder="Destek konusu (Örn: Depresyon)" />

          <label>Ne sıklıkla destek almak istiyorsunuz?</label>
          <select>
            <option>Haftada bir</option>
            <option>İki haftada bir</option>
            <option>Ayda bir</option>
            <option>Gerektiğinde</option>
          </select>

          <label>Hangi özelliklere sahip bir uzman arıyorsunuz?</label>
          <input type="text" placeholder="Özellikler (Örn: Empatik)" />

          <button type="submit">Randevuyu Tamamla</button>
        </form>
      </div>
    </div>
  );
}

export default AppointmentModal;
