// src/pages/AddPsychologist.js
import React, { useState } from 'react';
import { db, storage, auth } from '../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import styles from './AddPsychologist.module.css'; // CSS Modülünü ekleyin

function AddPsychologist() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [specialties, setSpecialties] = useState('');
  const [bio, setBio] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(''); // Başarı mesajı state'i

  const handleImageUpload = (e) => {
    setImage(e.target.files[0]);
  };

  const handleAddPsychologist = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      let imageUrl = '';

      if (image) {
        const storageRef = ref(storage, `psychologists/${user.uid}/${image.name}`);
        await uploadBytes(storageRef, image);
        imageUrl = await getDownloadURL(storageRef);
      }

      await setDoc(doc(db, 'psychologists', user.uid), {
        uid: user.uid,
        name,
        email,
        specialties: specialties.split(','),
        bio,
        price,
        imgSrc: imageUrl,
      });

      setSuccessMessage('Psikolog başarıyla eklendi!'); // Başarı mesajı ayarlandı
      // Formu temizleme
      setName('');
      setEmail('');
      setPassword('');
      setSpecialties('');
      setBio('');
      setPrice('');
      setImage(null);
    } catch (error) {
      console.error('Psikolog ekleme hatası:', error);
      alert('Psikolog eklerken bir hata oluştu: ' + error.message);
    }
  };

  return (
    <div className={styles.addPsychologistContainer}>
      <h2>Psikolog Ekle</h2>
      <form onSubmit={handleAddPsychologist} className={styles.addPsychologistForm}>
        <input
          type="text"
          placeholder="İsim"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Şifre"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Uzmanlık Alanları (virgülle ayırın)"
          value={specialties}
          onChange={(e) => setSpecialties(e.target.value)}
          required
        />
        <textarea
          placeholder="Kısa Özgeçmiş"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          required
        />
        <input
          type="number"
          placeholder="Ücret (₺)"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
        <input
          type="file"
          onChange={handleImageUpload}
          required
        />
        <button type="submit" className={styles.btn}>Psikolog Ekle</button>
        {successMessage && <p className={styles.successMessage}>{successMessage}</p>} {/* Başarı mesajını göster */}
      </form>
    </div>
  );
}

export default AddPsychologist;
