import React, { useState } from 'react';
import styles from './CustomerInfoForm.module.css';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

function CustomerInfoForm({ psychologistUid, selectedType, selectedDay, onSubmit, onClose }) {
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    phone: '',
    notes: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo({ ...customerInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Firestore'a kaydetme işlemi
    try {
      if (!psychologistUid) {
        console.error('Psikolog kimliği tanımsız!');
        return;
      }

      const newAppointment = {
        ...customerInfo,
        type: selectedType,
        day: selectedDay,
        date: new Date(),
        status: 'pending'
      };

      await addDoc(collection(db, `appointments/${psychologistUid}/appointments`), newAppointment);
      alert('Randevu başarıyla kaydedildi!');

      // Üst bileşene müşteri bilgisini gönder
      onSubmit(customerInfo);
    } catch (error) {
      console.error('Randevu kaydedilirken bir hata oluştu:', error);
    }
  };

  return (
    <div className={styles.customerInfoForm}>
      <h2 className={styles.title}>Kişisel Bilgileriniz</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label className={styles.label}>İsim:</label>
          <input type="text" name="name" value={customerInfo.name} onChange={handleChange} required className={styles.input} />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Email:</label>
          <input type="email" name="email" value={customerInfo.email} onChange={handleChange} required className={styles.input} />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Telefon:</label>
          <input type="tel" name="phone" value={customerInfo.phone} onChange={handleChange} required className={styles.input} />
        </div>
        <div className={styles.formGroup}>
          <label className={styles.label}>Özel Not:</label>
          <textarea name="notes" value={customerInfo.notes} onChange={handleChange} className={styles.textarea}></textarea>
        </div>
        <div className={styles.formActions}>
          <button type="submit" className={styles.btnSave}>Kaydet</button>
        </div>
      </form>
    </div>
  );
}

export default CustomerInfoForm;
