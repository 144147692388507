import React from 'react';
import styles from './AlertModal.module.css';
import { auth } from '../firebaseConfig'; // Firebase auth'ı içe aktar

function AlertModal({ message, onClose }) {
    const handleClose = () => {
        // Çıkış yapma işlemi
        auth.signOut().then(() => {
            onClose(); // Modalı kapat
        }).catch((error) => {
            console.error("Çıkış yaparken hata:", error);
        });
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2>Uyarı</h2>
                <p>{message}</p>
                <button onClick={handleClose}>Tamam</button>
            </div>
        </div>
    );
}

export default AlertModal;
