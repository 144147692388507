import React, { useState } from 'react';

function BriefPsychiatricRatingScale() {
  // Her sorunun cevaplarını tutan state
  const [answers, setAnswers] = useState(Array(18).fill(0));

  // Sorular ve seçenekler
  const questions = [
    {
      question: '1. SOMATİK UĞRAŞLAR: Beden sağlığıyla uğraşma, fiziki hastalıktan korkma, hipokondriyazis',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '2. ANKSİYETE: Hal veya gelecek için aşırı endişe, korku',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '3. DUYGUSAL İÇE KAPANMA: Spontan interaksiyon kaybı, izolasyon, kişiler arası ilişkilerde yetersizlik',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '4. DÜŞÜNCE ÇÖZÜLMESİ: Konfüzyon, kopuk, bağlantısız, dezorganize düşünce süreçleri',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '5. SUÇLULUK DUYGULARI: Kendini suçlama, utanma, geçmişteki davranışlardan pişmanlık duyma',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '6. GERGİNLİK: Sinirlilik, aşırı hareketlilik, gerginliğin fizik veya motor belirtileri',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '7. MANİYERİZM VE POSTÜR: Tuhaf, acayip, doğal olmayan motor davranış (tikler dışında)',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '8. GRANDİYOZİTE: Kendisi hakkında abartmalı düşünceler, olağan dışı güç ve yeteneğe sahip olduğu inancı',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '9. DEPRESİF DUYGUDURUM: Elem, keder, umutsuzluk, kötümserlik',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '10. DÜŞMANCA DAVRANIŞ: Başkalarına karşı düşmanlık, kavgacılık, nefret',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '11. KUŞKUCULUK: Güvensizlik, başkalarının kasıtlı olarak kötülük yapacağına inanma',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '12. HALLÜSİNATUAR DAVRANIŞ: Normal dışı, uyaran olmaksızın algılama',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '13. MOTOR YAVAŞLAMA: Yavaşlamış, zayıflamış hareket veya konuşma, beden kuvvetinde azalma',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '14. İŞBİRLİĞİ KURMAMA: Direnç, temkinlilik, otoriteyi reddetme',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '15. OLAĞAN DIŞI DÜŞÜNCELER: Olağan dışı, garip, tuhaf düşünceler',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '16. DUYGUSAL KÜNTLÜK: Azalmış duygusal ton, duyguların normal şiddetinde azalma',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '17. TAŞKINLIK: Artmış emosyonel ton, ajitasyon, tepkisellikte artma',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    },
    {
      question: '18. DEZORYANTASYON: Konfüzyon veya kişi, yer, zamana ait çağrışımların kaybı',
      options: ['0 = Yok', '1 = Çok hafif', '2 = Hafif', '3 = Orta', '4 = Orta-şiddetli', '5 = Şiddetli', '6 = Aşırı derecede şiddetli']
    }
  ];

  // Cevapları değiştiren fonksiyon
  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  // Toplam puanı hesaplayan fonksiyon
  const calculateTotalScore = () => {
    return answers.reduce((acc, curr) => acc + parseInt(curr), 0);
  };

  return (
    <div>
      <h1>Kısa Psikiyatrik Değerlendirme Ölçeği</h1>
      <p>Aşağıdaki soruları cevaplayarak ruh halinizi değerlendirin:</p>
      {questions.map((questionObj, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <p>{questionObj.question}</p>
          {questionObj.options.map((option, optionIndex) => (
            <label key={optionIndex} style={{ display: 'block', margin: '5px 0' }}>
              <input
                type="radio"
                name={`question-${index}`}
                value={optionIndex}
                checked={answers[index] === optionIndex}
                onChange={() => handleChange(index, optionIndex)}
              />
              {option}
            </label>
          ))}
        </div>
      ))}

      <h2>Toplam Puan: {calculateTotalScore()}</h2>
    </div>
  );
}

export default BriefPsychiatricRatingScale;
