import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { checkPsychologistLogin } from './PsychologistLogin'; // PsychologistLogin.js dosyasından fonksiyonu içe aktar
import AlertModal from '../components/AlertModal'; // Alert modal bileşenini içe aktar
import './LoginPage.css';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginType, setLoginType] = useState('admin'); // Varsayılan olarak admin olarak ayarlayın
    const [showAlert, setShowAlert] = useState(false); // Alert durumu
    const [alertMessage, setAlertMessage] = useState(''); // Alert mesajı
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            if (loginType === 'psychologist') {
                // Psikolog girişini kontrol et
                const isAuthenticated = await checkPsychologistLogin(email, password);
                if (isAuthenticated) {
                    navigate('/psychologist-dashboard'); // Psikolog girişi başarılıysa yönlendir
                } else {
                    setAlertMessage('Psikolog kaydı bulunamadı veya giriş başarısız.');
                    setShowAlert(true);
                    clearForm();
                }
            } else {
                // Admin kontrolü için Firestore'dan veriyi çek
                const userCredential = await signInWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;
    
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
    
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (userData.role === 'admin') {
                        navigate('/admin'); // Admin yönlendirmesi
                    } else {
                        setAlertMessage('Sadece admin kullanıcılar giriş yapabilir.');
                        setShowAlert(true);
                        clearForm();
                        await auth.signOut(); // Yanlış kullanıcı admin girişi yapmaya çalıştığında çıkış yap
                    }
                } else {
                    setAlertMessage('Kullanıcı belgesi bulunamadı.');
                    setShowAlert(true);
                    clearForm();
                    await auth.signOut(); // Kullanıcı belgesi bulunamadığında çıkış yap
                }
            }
        } catch (error) {
            console.error('Giriş hatası:', error);
            setAlertMessage('Giriş yaparken bir hata oluştu: ' + error.message);
            setShowAlert(true);
            clearForm();
            await auth.signOut(); // Hata olduğunda çıkış yap ve giriş yapılmış gibi göstermeyin
        }
    };
    const clearForm = () => {
        setEmail('');
        setPassword('');
    };

    const handleAlertClose = () => {
        setShowAlert(false);
        auth.signOut(); // Otomatik çıkış
        navigate('/'); // Ana sayfaya yönlendir
    };

    return (
        <div className="login-container">
            <h2>{loginType === 'admin' ? 'Yönetici Girişi' : 'Psikolog Girişi'}</h2>
            <form onSubmit={handleLogin}>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Şifre"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit" className="btn">
                    {loginType === 'admin' ? 'Yönetici Girişi Yap' : 'Psikolog Girişi Yap'}
                </button>
            </form>

            <div className="login-options">
                <button className="btn secondary" onClick={() => setLoginType('admin')}>
                    Yönetici Girişi
                </button>
                <button className="btn secondary" onClick={() => setLoginType('psychologist')}>
                    Psikolog Girişi
                </button>
            </div>

            <p>{loginType === 'admin' ? 'Psikolog olarak giriş yapmak için yukarıdaki butona tıklayın.' : 'Yönetici olarak giriş yapmak için yukarıdaki butona tıklayın.'}</p>

            {showAlert && (
                <AlertModal message={alertMessage} onClose={handleAlertClose} />
            )}
        </div>
    );
}

export default LoginPage;
