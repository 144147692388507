// src/UserContext.js
import React, { createContext, useEffect, useState } from 'react';
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isPsychologist, setIsPsychologist] = useState(false); // Psikolog durumu
    const [psychologistName, setPsychologistName] = useState(''); // Psikolog adı durumu

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setCurrentUser(user);
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setIsAdmin(userData.role === 'admin');
                }
                const psychologistDoc = await getDoc(doc(db, 'psychologists', user.uid));
                if (psychologistDoc.exists()) {
                    setIsPsychologist(true); // Psikolog mu kontrol et
                    setPsychologistName(psychologistDoc.data().name); // Psikolog adını al
                } else {
                    setIsPsychologist(false);
                }
            } else {
                setCurrentUser(null);
                setIsAdmin(false);
                setIsPsychologist(false);
                setPsychologistName(''); // Kullanıcı çıkınca ismi temizle
            }
        });
        return () => unsubscribe();
    }, []);

    return (
        <UserContext.Provider value={{ currentUser, isAdmin, isPsychologist, psychologistName }}>
            {children}
        </UserContext.Provider>
    );
};
