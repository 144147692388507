import React, { useState } from 'react';
import { db, storage } from '../firebaseConfig';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { setDoc, doc } from 'firebase/firestore';
import styles from './PsychologistApplication.module.css'; // CSS modülünü içe aktar

function PsychologistApplication() {
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [specialties, setSpecialties] = useState('');
    const [experience, setExperience] = useState('');
    const [resume, setResume] = useState(null);
    const [diploma, setDiploma] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const applicationRef = doc(db, 'psychologistApplications', name);
            const resumeRef = ref(storage, `applications/${name}/resume/${resume.name}`);
            const diplomaRef = ref(storage, `applications/${name}/diploma/${diploma.name}`);

            // Dosyaları yükleme
            await uploadBytes(resumeRef, resume);
            await uploadBytes(diplomaRef, diploma);

            const resumeUrl = await getDownloadURL(resumeRef);
            const diplomaUrl = await getDownloadURL(diplomaRef);

            // Başvuru bilgilerini Firestore'a kaydetme
            await setDoc(applicationRef, {
                name,
                city,
                specialties: specialties.split(','),
                experience,
                resume: resumeUrl,
                diploma: diplomaUrl,
            });

            setSuccessMessage('Başvurunuz başarıyla kaydedildi!');
            // Formu temizleme
            setName('');
            setCity('');
            setSpecialties('');
            setExperience('');
            setResume(null);
            setDiploma(null);
        } catch (error) {
            console.error('Başvuru kaydedilirken bir hata oldu:', error);
            alert('Başvuru kaydedilirken bir hata oldu.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.applicationContainer}>
            <div className={styles.applicationForm}>
                <h2>Psikolog Başvurusu</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Ad Soyad"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Yaşadığı Şehir"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Çalışma Alanları (virgülle ayrılmış)"
                        value={specialties}
                        onChange={(e) => setSpecialties(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Deneyim"
                        value={experience}
                        onChange={(e) => setExperience(e.target.value)}
                        required
                    />

                     {/* Özgeçmiş yükleme alanı */}
      <label htmlFor="resume">Özgeçmiş (Lütfen PDF olarak yükleyiniz):</label>
                    <input
                        
                        type="file"
                        placeholder='Özgeçmiş'
                        accept='.pdf'
                        onChange={(e) => setResume(e.target.files[0])}
                        required
                    />
                     {/* Diploma yükleme alanı */}
      <label htmlFor="diploma">Diploma (Lütfen PDF olarak yükleyiniz):</label>
                    <input
                        type="file"
                        placeholder='Diploma'
                        accept='.pdf'
                        onChange={(e) => setDiploma(e.target.files[0])}
                        required
                    />
                    <button type="submit" disabled={loading}>
                        {loading ? 'Yükleniyor...' : 'Başvuruyu Gönder'}
                    </button>
                </form>
                {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
            </div>
        </div>
    );
}

export default PsychologistApplication;
