import React, { useState } from 'react';

function BeckAnxietyInventory() {
  // Hastanın adı ve tarih için state
  const [patientName, setPatientName] = useState('');
  const [date, setDate] = useState('');

  // Her sorunun cevaplarını tutan state
  const [answers, setAnswers] = useState(Array(21).fill(0));

  // Sorular ve seçenekler
  const questions = [
    'Bedeninizin herhangi bir yerinde uyuşma veya karıncalanma',
    'Sıcak/ateş basmaları',
    'Bacaklarda halsizlik, titreme',
    'Gevşeyememe',
    'Çok kötü şeyler olacak korkusu',
    'Baş dönmesi veya sersemlik',
    'Kalp çarpıntısı',
    'Dengeyi kaybetme duygusu',
    'Dehşete kapılma',
    'Sinirlilik',
    'Boğuluyormuş gibi olma duygusu',
    'Ellerin titremesi',
    'Titreklik',
    'Kontrolü kaybetme korkusu',
    'Nefes almada güçlük',
    'Ölüm korkusu',
    'Korkuya kapılma',
    'Midede hazımsızlık ya da rahatsızlık hissi',
    'Baygınlık',
    'Yüzün kızarması',
    'Terleme (sıcaklığa bağlı olmayan)'
  ];

  // Seçenekler
  const options = [
    'Hiç',
    'Hafif düzeyde (Beni pek etkilemedi)',
    'Orta düzeyde (Hoş değildi ama katlanabildim)',
    'Ciddi düzeyde (Dayanmakta çok zorlandım)'
  ];

  // Cevapları değiştiren fonksiyon
  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  // Toplam skoru hesaplayan fonksiyon
  const calculateTotalScore = () => {
    return answers.reduce((acc, curr) => acc + parseInt(curr), 0);
  };

  return (
    <div>
      <h1>Beck Anksiyete Ölçeği</h1>
      <p>Hastanın Soyadı, Adı: <input type="text" value={patientName} onChange={(e) => setPatientName(e.target.value)} /></p>
      <p>Tarih: <input type="date" value={date} onChange={(e) => setDate(e.target.value)} /></p>
      <p>Aşağıda insanların kaygılı ya da endişeli oldukları zamanlarda yaşadıkları bazı belirtiler verilmiştir.</p>
      <p>Daha sonra, her maddedeki belirtinin BUGÜN DAHİL SON BİR (1) HAFTADIR sizi ne kadar rahatsız ettiğini işaretleyiniz.</p>

      {questions.map((question, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <p>{question}</p>
          {options.map((option, optionIndex) => (
            <label key={optionIndex} style={{ display: 'block', margin: '5px 0' }}>
              <input
                type="radio"
                name={`question-${index}`}
                value={optionIndex}
                checked={answers[index] === optionIndex}
                onChange={() => handleChange(index, optionIndex)}
              />
              {option}
            </label>
          ))}
        </div>
      ))}

      <h2>Toplam BECK-A Skoru: {calculateTotalScore()}</h2>
    </div>
  );
}

export default BeckAnxietyInventory;
