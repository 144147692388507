import React from 'react';
import styles from './EventDetailModal.module.css';

function EventDetailModal({ event, onClose, onDelete }) {
    const handleDelete = () => {
        if (window.confirm(`Etkinliği silmek istediğinize emin misiniz? '${event.title}'`)) {
            onDelete(event.id); // Event id'sini silme işlemi için gönder
            onClose(); // Modalı kapat
        }
    };

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <h2>Etkinlik Detayları</h2>
                <p><strong>Başlık:</strong> {event.title}</p>
                <p><strong>Başlangıç:</strong> {new Date(event.start).toLocaleString()}</p>
                <p><strong>Bitiş:</strong> {new Date(event.end).toLocaleString()}</p>
                <button className={styles.deleteButton} onClick={handleDelete}>Sil</button> {/* Silme butonu */}
                <button className={styles.closeButton} onClick={onClose}>Kapat</button> {/* Kapatma butonu */}
            </div>
        </div>
    );
}

export default EventDetailModal;
