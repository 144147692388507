import React from 'react';
import styles from './CancellationAndRefundPolicy.module.css'; // CSS modülünü ekle

const CancellationAndRefundPolicy = () => {
  return (
    <div className={styles.cancellationPolicy}>
      <h1>Kullanıcı İptal ve İade Sözleşmesi</h1>

      <h2>TARAFLAR</h2>
      <p>
        İşbu Kullanıcı Sözleşmesi (“Sözleşme”), Uygun Psikolog internet sitesi üzerinden üye olacak veya
        ziyaret edecek kişiler (Kullanıcı(lar)) ile Aygün Psikolojik Danışmanlık Şirketi (“UYGUN PSIKOLOG”)
        arasında akdedilmiştir. İşbu Sözleşme’de UYGUN PSIKOLOG ve Kullanıcı ayrı ayrı "Taraf" ve birlikte
        "Taraflar" olarak anılacaklardır.
      </p>

      <h2>TANIMLAR</h2>
      <p>
        <strong>Danışan:</strong> Platform aracılığıyla satın alınan danışmanlık hizmetinden ücret karşılığında faydalanan
        kişi ya da kurumları ifade etmektedir.
      </p>
      <p>
        <strong>Danışman:</strong> Platform aracılığıyla danışmanlık hizmeti sunan psikolog ve psikolojik danışman
        sıfatını haiz olduğunu beyan eden ve bu beyanlarını resmi belgelerle (diploma, sertifika vb.)
        ispatlamış kişileri ifade etmektedir.
      </p>
      <p>
        <strong>UYGUN PSIKOLOG:</strong> Aygün Psikolojik Danışmanlık Şirketine ait internet sitesi markası ve siteye
        bağlı mobil uygulama marka adıdır.
      </p>
      <p>
        <strong>Kullanıcı:</strong> Platformu kullanan tüm ziyaretçi ve danışan kullanıcılarının genel tanımıdır.
      </p>
      <p>
        <strong>Mesafeli Satış Sözleşmesi:</strong> Danışan ve Danışman arasında çevrimiçi psikolojik danışmanlık
        hizmeti sunulmasına ilişkin hüküm ve koşulların belirlendiği satış sözleşmesini ifade eder.
      </p>
      <p>
        <strong>Platform:</strong> www.uygunpsikolog.com domain adına sahip internet sitesi ve online mobil uygulama
        marketlerinden mobil cihazlara ücretli ya da ücretsiz olarak indirilebilen, uygulama içi satın alma
        hizmetleri taşıyan UYGUN PSIKOLOG’a ait, UYGUN PSIKOLOG adını taşıyan uygulamaların tamamıdır.
      </p>

      <h2>SÖZLEŞME’NİN KAPSAMI VE AMACI</h2>
      <p>
        İşbu Sözleşme’nin konusu Kullanıcı’nın Platform aracılığıyla tamamen kendisi tarafından belirlenen
        veya tercih etmesi halinde UYGUN PSIKOLOG tarafından eşleştirilen Danışmanlar arasından
        kendisinin seçeceği bir Danışman’dan danışmanlık hizmeti satın almasına aracılık edilmesine ilişkin
        hüküm ve koşulların belirlenmesidir.
      </p>
      <p>
        Kullanıcı Platform’a erişim sağlayarak, Platform üzerinden bir Kullanıcı Hesabı oluşturarak veya hizmet alma
        talebini ileterek bu Kullanıcı Sözleşmesi’nin tamamını okuduğunu ve içeriğinin tamamını anladığını
        ve sözleşme hüküm ve koşullarına peşinen uyacağını kabul, beyan ve taahhüt eder. Şüpheye mahal vermemek
        adına, Kullanıcı’nın Danışman ile Platform dışında UYGUN PSIKOLOG’un bilgisi dahilinde olmadan aldığı
        herhangi bir danışmanlık hizmeti bu sözleşme kapsamında değildir.
      </p>

      <h2>PLANLAR VE SEANS PAKETLERİ</h2>
      <p>
        Platform’a üye olmak şartıyla Kullanıcı, seçeceği bir Danışman’la ücretsiz 15 (on beş) dakikalık ön
        görüşme yapabilmektedir. Ancak ön görüşme terapötik süreç için tanışma amaçlı olup, tedavi,
        danışma veya tavsiye amaçlı değildir. Platform’da Kullanıcıya seans paketleri sunulmaktadır. Bu plan
        kapsamında Kullanıcı, bir (1) seanslık; 2 (iki) seanslık; 3(üç) seanslık ve 4 (dört) seanslık olmak üzere
        farklı seans paketlerini seçebilmektedir. Kullanıcı her bir seans için 40-50 dakikalık çevrimiçi görüşme
        hakkına sahiptir.
      </p>
      <p>
        Seans paketlerinin içeriği ve fiyatları bu kapsamda alınacak danışmanlık hizmetinin
        ücretine bağlı olarak değişik zamanlarda farklılık gösterebilmektedir. Kullanıcı seans paketi satın alma
        sürecini tamamlamadıkça mevcut seans paketi fiyatlarında değişiklik yapılması durumunda satın alma
        esnasındaki yeni fiyatla bağlı olacağını kabul, beyan ve taahhüt eder.
      </p>

      <h2>İPTAL VE İADE</h2>
      <p>
        Kullanıcı seans paketini satın aldığı tarihten itibaren 14 (on dört) gün içerisinde (“Cayma Süresi”)
        kullanmadığı seanslar bakımından gerekçe göstermeksizin ve cezai şartsız olarak iade talep edebilir.
        Kullanıcı seans paketini satın aldığı tarihten itibaren bir (1) ay içerisinde (“İade Süresi”) ise
        kullanılmamış seanslar bakımından iade talep edebilecektir.
      </p>
      <p>
        İade Süresi içerisindeki iade durumunda indirimsiz seans ücreti ile indirimli seans ücreti arasındaki fark
        Kullanıcı aleyhine cezai şart teşkil edecek olup, kullanılmış olan seanslar indirimsiz seans ücreti üzerinden
        fiyatlandırılarak toplam seans paketi fiyatından düşülmek suretiyle hesap edilen tutar kullanıcıya
        iadesi edilecektir.
      </p>

      <h2>TARAFLAR’IN HAK VE YÜKÜMLÜLÜKLERİ</h2>
      <p>
        Kullanıcı’nın Platform üzerinden danışmanlık hizmeti satın alması için işbu Sözleşme’yi onaylaması ve
        talep edilen bilgileri güncel ve doğru bilgilerle doldurması gerekmektedir. Platform’u kullanmak
        isteyen kişilerin 18 (on sekiz) yaşını doldurmuş olması gerekmektedir. Kullanıcı Sözleşmesi’ni
        doldururken doğru ve güncel bilgi sağlamayan Kullanıcı, bu sebeple doğabilecek tüm zararlardan
        bizzat sorumlu olacaktır.
      </p>
      <p>
        UYGUN PSIKOLOG, Kullanıcı’nın Platform’da bilgilerine yer verilen seans paketlerinden birini satın alarak
        Kullanıcı’nın kendisi tarafından belirlenen bir Danışman’dan Platform aracılığıyla danışmanlık hizmeti
        satın almasına elektronik ortam sağlayarak aracılık etmektedir.
      </p>
      <p>
        UYGUN PSIKOLOG, sunmuş olduğu Platform’un 6502 sayılı Tüketicinin Korunması Hakkındaki Kanun
        ve Elektronik Ticaret Aracı Hizmet Sağlayıcı ve Elektronik Ticaret Hizmet Sağlayıcılar Hakkında
        Yönetmelik hükümlerine uygun olduğunu kabul, beyan ve taahhüt eder.
      </p>
      <p>
        Platform’da yer alan içerikler tanı, teşhis ve tedavi önerisi olarak kabul edilemez ve bu içerikler
        herhangi bir tavsiye niteliğinde değildir. Danışan, Danışman’ın seans esnasında kendisine vereceği
        bilgi ve tavsiyelerin yalnızca Danışman’ın kişisel görüşleri olduğunu ve Danışman’ın beyan edeceği
        fikir ve görüşler nedeniyle Danışan’ın veya üçüncü kişilerin uğrayabileceği zararlardan dolayı UYGUN
        PSIKOLOG’un herhangi bir sorumluluğu bulunmadığını kabul eder.
      </p>
      <p>
        Danışman tarafından Platform’da tıbbi teşhis ve tedavi uygulanmayacağı, bu yönde bir vaatte
        bulunulmayacağı, reçete yazılmayacağı ve Danışanlar’ın reçeteli ilaç almaya yönlendirilmeyeceği
        kabul, beyan ve taahhüt edilmektedir. Danışan kendisine tedavi için ilaç yazılmasında ısrarcı ise
        Danışman tarafından danışmanlık sona erdirilecektir. Kullanıcı, Sözleşme’nin bu sebeple sona ermesi
        nedeni ile UYGUN PSIKOLOG’dan herhangi bir talepte bulunmayacağını kabul, beyan ve taahhüt eder.
      </p>
      <p>
        UYGUN PSIKOLOG’un aracı hizmet sağlayıcı olarak yükümlülükleri Madde 4.6 kapsamında yer verilen
        hususların yerine getirilip getirilmediğini denetleme yükümlülüğü ve imkanı bulunmamaktadır.
      </p>
      <p>
        Kullanıcı, UYGUN PSIKOLOG’un Yönetmelik uyarınca aracı hizmet sağlayıcı sıfatıyla hareket ettiğini ve
        UYGUN PSIKOLOG’a karşı Danışan ve Danışman arasındaki Mesafeli Satış Sözleşmesi’nden kaynaklı
        hiçbir talep ileri sürmeyeceğini kabul, beyan ve taahhüt eder.
      </p>
      <p>
        Danışman tarafından Danışan’ın intihar, panik atak, nöbet geçirme gibi bir hal, düşünce veya eylem
        içinde olduğu yönünde UYGUN PSIKOLOG’a bilgi verilmesi halinde, bu kişinin Platform’u kullanmasına
        izin verilmez ve daha önce üye olmuş bir Kullanıcı ise üyelik derhal sonlandırılır. Danışan’ın 18
        (on sekiz) yaşından küçük olduğu ya da online psikolojik danışmanlık alması önerilmeyen kişilerden
        olduğuna ilişkin Danışman tarafından UYGUN PSIKOLOG’a bilgi verilmesi halinde danışmanlık hizmeti
        derhal sonlandırılır ve Danışan üyelikten çıkarılır. Bu durumda Danışan, UYGUN PSIKOLOG aracılığıyla
        bir daha danışmanlık hizmeti alamayacaktır.
      </p>
      <p>
        Kullanıcı, kişisel bilgileri hakkında yanlış bilgi vererek danışmanlık hizmeti almışsa UYGUN
        PSIKOLOG’un her türlü yasal işlem yapma hakkı saklı olmak kaydıyla UYGUN PSIKOLOG’un bu nedenle
        uğramış olduğu tüm doğrudan ve dolaylı zararlarını tazmin edeceğini kabul, beyan ve taahhüt eder.
      </p>
      <p>
        Kullanıcı, Platform aracılığıyla özellikle tehdit, şantaj, hakaret, iftira içeren bilgi, yazı, görsel ve hukuka
        aykırı ve ahlaka aykırı herhangi bir paylaşımda bulunmayacağını kabul, beyan ve taahhüt eder.
      </p>
      <p>
        Kullanıcı’nın bu hükme aykırı hareket etmesi nedeniyle üçüncü kişilerin uğramış olduğu zararlardan
        UYGUN PSIKOLOG’un sorumluluğu bulunmamaktadır. Kullanıcı, çevrimiçi görüşme randevusu
        kapsamında Danışman tarafından sunulacak olan danışmanlık hizmeti ile ilgili olarak Kullanıcı’ya bilgi
        verilmesi açısından Danışman’ın sorumlu olduğunu ve UYGUN PSIKOLOG’un herhangi bir
        sorumluluğunun bulunmadığını kabul, beyan ve taahhüt eder.
      </p>
      <p>
        Kullanıcı, UYGUN PSIKOLOG’un Platform’un teknik bakım ve gereklilikleri için yapacağı çalışma
        nedeniyle belirli zaman aralıklarında hizmeti geçici olarak bir süreliğine durdurma hakkına sahip
        olduğunu kabul eder. Kullanıcı, Platform’a erişim sağlamak için kullanmakta olduğu donanım, internet
        vb. erişim için gerekli bilumum ihtiyaçlarının yeterliliğinden kendi sorumlu olup Platform aracılığıyla
        sunulan hizmetlere bu nedenlerle erişememesi veya hizmetleri kullanamaması halinde UYGUN
        PSIKOLOG’un sorumluluğu bulunmadığını kabul eder.
      </p>
    </div>
  );
};

export default CancellationAndRefundPolicy;
