import React from 'react';
import styles from './Gizlilik.module.css';

function Gizlilik() {
  return (
    <div className={styles.gizlilikContainer}>
      <h1>Gizlilik Politikası</h1>
      <p>
        Uygunpsikolog.com olarak kişisel gizlilik haklarınıza saygı duyuyor ve sitemizde geçirdiğiniz
        süre boyunca bunu sağlamak için çaba sarf ediyoruz. Kişisel bilgilerinizin güvenliği ile ilgili
        açıklamalar aşağıda sunulmuştur.
      </p>

      <h2>1. Gizlilik Politikasının Kapsamı</h2>
      <p>
        Uygunpsikolog.com, üyelik aşamasında ve sonrasında sizden bazı kişisel bilgilerinizi talep eder. Vermiş olduğunuz bilgiler,
        üyelik ve site sözleşmesinde belirtilen kurallar ve amaçlar dışında kullanılmayacak ve üçüncü şahıslar ile paylaşılmayacaktır.
        Kamu güvenliğinden sorumlu resmi makamlarca talep edilmesi halinde kişisel bilgilerinizi paylaşma hakkı saklıdır.
      </p>
      <p>
        Kayıtlı kişisel bilgileriniz, hiçbir şekilde satılamaz, kiralanamaz veya başka bir kurum ile paylaşılmaz. Toplanan bilgiler
        güvenli bir ortamda saklanır. Uygunpsikolog.com, bilgilerinizi korumak için gerekli tüm önlemleri alır ancak güvenlik
        konularında garanti vermez.
      </p>

      <h2>2. Dış Bağlantılar</h2>
      <p>
        Uygulama web sitesi, başka sitelere bağlantılar verebilir. Site, bu bağlantılar aracılığıyla erişilen sitelerin gizlilik
        uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımaz. Diğer web sitelerindeki kullanım ile ilgili gizlilik
        sözleşmeleri geçerlidir.
      </p>

      <h2>3. Reklamlar</h2>
      <p>
        Sitemizde dışarıdan şirketlerin reklamları yayınlanmaktadır. Bu reklamlar çerez (cookies) içerebilir ve bu şirketler tarafından
        çerez bilgileri toplanabilir; bizim bu bilgiye ulaşmamız mümkün değildir.
      </p>

      <h2>4. Bilgi Güncelleme ve Değişiklik</h2>
      <p>
        Uygunpsikolog.com, gizlilik ve veri koruma prensiplerini güncel tutmak ve ilgili mevzuata uygun hale getirmek için Gizlilik
        Politikasının içeriğini dilediği zaman değiştirebilir. Değişiklikler, Uygunpsikolog.com internet sitesinde ilan edilecektir.
        Gizlilik Politikasının en güncel haline buradan ulaşabilirsiniz. Değişikliklerin ardından hizmetleri kullanmaya devam etmeniz,
        bu değişiklikleri kabul ettiğiniz anlamına gelir. Değişiklikler, internet sitesinde yayınlandığı tarihte yürürlük kazanır.
      </p>

      <h2>5. Çerezler (Cookies)</h2>
      <p>
        Çerezleri, Uygunpsikolog.com’un kullanımını kolaylaştırmak ve danışmanlık hizmetlerimizi ihtiyaçlarınıza göre özelleştirmek
        amacıyla kullanırız. Çerezler, sitemizi nasıl kullandığınızı anlamamıza yardımcı olan isimsiz ve toplu istatistik verileri
        toplamak amacıyla da kullanılmaktadır. Bu veriler, kimliğinizi tanımlamamıza yardımcı olabilecek bilgiler değildir.
      </p>
      <p>
        Site, gizli bilgileri özel ve gizli tutmayı taahhüt eder ve gerekli önlemleri almayı hedefler. Her üye, bu hizmeti kullanarak
        veya web sitesini ziyaret ederek gizlilik politikası hüküm ve şartlarını kabul etmiş sayılır.
      </p>
    </div>
  );
}

export default Gizlilik;
