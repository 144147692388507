import React, { useState } from 'react';

function PanicDisorderScale() {
  // Soruların cevaplarını tutan state
  const [answers, setAnswers] = useState(Array(7).fill(0)); 

  // Her sorunun ayrıntılı açıklamasıyla birlikte
  const questions = [
    {
      question: '1. PANİK ATAĞI SIKLIĞI, SINIRLI BELİRTİLİ ATAKLAR DAHİL',
      options: [
        '0=Hiç panik atağı ya da sınırlı belirtili panik atağı (SBP) yok',
        '1=Hafif, ortalama tam panik atağı sayısı haftada birden az ve bir SBP/gün’den daha fazla değil.',
        '2=Orta, haftada bir veya iki tam panik atağı ve/veya çoğul SBP’ler/gün.',
        '3=Şiddetli, haftada ikiden çok tam panik atakları ama ortalama günde birden çok değil.',
        '4=Aşırı, günde birden fazla tam panik atağı geçirme, geçirilen günlerin sayısının geçirilmeyenlerden fazla olması.'
      ]
    },
    {
      question: '2. PANİK ATAKLARI SIRASINDAKİ ZORLANMA, SINIRLI BELİRTİLİ ATAKLAR DAHİL',
      options: [
        '0=Panik atağı ya da sınırlı belirtili panik atağı yok ya da ataklar sırasında hiç zorlanma yok.',
        '1=Ilımlı zorlanma, ama hiç ya da az bir aksama ile etkinliği sürdürebilmektedir.',
        '2=Orta zorlanma, ama hala idare edilebilir, etkinliğini sürdürebilmekte ve/veya konsantrasyonunu idame ettirmekte, ama bunları güçlükle yapabilmektedir.',
        '3=Şiddetli, belirgin zorlanma ve aksama, konsantrasyonunu yitirir ve/veya etkinliğini durdurmak zorundadır.',
        '4=Aşırı, şiddetli ve yetiyitimi yaratıcı zorlanma, etkinliğini durdurmak zorunda, eğer mümkünse odayı ya da durumu terkedecektir.'
      ]
    },
    {
      question: '3. BEKLENTİ ANKSİYETESİNİN ŞİDDETİ',
      options: [
        '0=Panik atağı hakkında tasa yok.',
        '1=Ilımlı, arasıra korku, endişe ya da kaygılı beklentisi oluyor.',
        '2=Orta, sıklıkla endişeli, korkulu ya da kaygılı beklenti içinde.',
        '3=Şiddetli, panik atağı hakkındaki korku, endişe ve kaygılı beklentilerle zihinsel uğraşı var.',
        '4=Aşırı, neredeyse sabit, yetiyitirici anksiyete, panik atağı hakkındaki korku, endişe ve kaygılı beklenti nedeniyle önemli görevleri sürdürememektedir.'
      ]
    },
    {
      question: '4. AGORAFOBİK KORKU / KAÇINMA',
      options: [
        '0=Hiç, korku ya da kaçınma yok.',
        '1=Ilımlı, arasıra korku ve/veya kaçınma, ama genellikle durumla yüzleşebilir.',
        '2=Orta, dikkat çekici korku ve/veya kaçınma, ama hala kontrol edilebilir.',
        '3=Şiddetli, yoğun kaçınma; fobiye uyabilmek için büyük ölçüde yaşam biçimi değişikliği gerekmektedir.',
        '4=Aşırı, yaygın yetiyitirici korku ve/veya kaçınma. Yaşam biçiminde yoğun değişiklik gerekmiştir.'
      ]
    },
    {
      question: '5. PANİK ATAĞI İLE İLİŞKİLİ DUYUMLARDAN KORKU / KAÇINMA',
      options: [
        '0=Zorlanma yaratıcı bedensel duyumları uyaran durum ya da etkinliklerden korku ya da kaçınma yok.',
        '1=Ilımlı, arasıra korku ve/veya kaçınma, ama genellikle bedensel duyumları uyaran durum ve etkinliklerle yüzleşebilir.',
        '2=Orta, göze çarpıcı kaçınma ama hala idare edilebilir.',
        '3=Şiddetli, yoğun kaçınma, yaşam biçiminde büyük ölçüde değişikliğe ya da işlevsellikte aksamaya yol açmaktadır.',
        '4=Aşırı, yaygın ve yetiyitimi yaratıcı kaçınma. Önemli görev ve etkinlikleri yapmayacak kadar yoğun bir yaşam biçimi değişikliği gerekmiştir.'
      ]
    },
    {
      question: '6. PANİK BOZUKLUĞUNA BAĞLI OLARAK ÇALIŞMA İŞLEVSELLİĞİNDE BOZULMA / AKSAMA',
      options: [
        '0=Panik bozukluğu belirtilerinden dolayı bozulma yok.',
        '1=Ilımlı, hafif bozulma, işin zorlaştığını hissetmekte ama performansı hala iyidir.',
        '2=Orta, belirtiler düzenli ve kesin aksamaya yol açmakta, ama hala kontrol edilebilir.',
        '3=Şiddetli, mesleksel performansta önemli ölçüde bozulmaya neden olmuştur.',
        '4=Aşırı, yeti yitirici belirtiler, çalışamamaktadır.'
      ]
    },
    {
      question: '7. PANİK BOZUKLUĞUNA BAĞLI OLARAK TOPLUMSAL İŞLEVSELLİKTE BOZULMA / AKSAMA',
      options: [
        '0=Bozulma yok.',
        '1=Ilımlı, hafif bozulma, toplumsal davranış niteliğinin biraz değiştiğini hissetmektedir.',
        '2=Orta, toplumsal yaşamda kesin aksama, ama hala kontrol edilebilir.',
        '3=Şiddetli, toplumsal performansta önemli ölçüde bozulmaya neden olmaktadır.',
        '4=Aşırı, yetiyitirici belirtiler, nadiren dışarı çıkmakta ve diğerleriyle etkileşmektedir.'
      ]
    }
  ];

  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const calculateTotalScore = () => {
    return answers.reduce((acc, curr) => acc + curr, 0);
  };

  return (
    <div>
      <h1>Panik Bozukluğu Şiddeti Ölçeği (PBŞÖ)</h1>
      {questions.map((questionObj, index) => (
        <div key={index}>
          <p>{questionObj.question}</p>
          <div>
            {questionObj.options.map((option, optionIndex) => (
              <label key={optionIndex} style={{ display: 'block', margin: '5px 0' }}>
                <input
                  type="radio"
                  name={`question-${index}`}
                  value={optionIndex}
                  checked={answers[index] === optionIndex}
                  onChange={() => handleChange(index, optionIndex)}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      ))}
      <h2>Toplam Puan: {calculateTotalScore()}</h2>
    </div>
  );
}

export default PanicDisorderScale;
