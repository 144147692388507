import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { UserContext } from '../UserContext';
import './Header.css';

function Header() {
    const { currentUser, isAdmin, isPsychologist, psychologistName } = useContext(UserContext);
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false); // Menü durumu için state
    const sideBarRef = useRef(null); // Side bar referansı
    const menuIconRef = useRef(null); // Hamburger menü ikon referansı

    const handleLogout = () => {
        auth.signOut();
        navigate('/');
    };

    const handleProfileClick = () => {
        if (isAdmin) {
            navigate('/admin');
        } else if (isPsychologist) {
            navigate('/psychologist-dashboard');
        } else {
            navigate('/');
        }
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Menü açma/kapama
    };

    // Sayfa üzerinde herhangi bir yere tıklanırsa side bar'ı kapat
    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Side bar ve hamburger menü ikonunun dışında bir yere tıklandıysa kapat
            if (
                sideBarRef.current && !sideBarRef.current.contains(event.target) &&
                menuIconRef.current && !menuIconRef.current.contains(event.target)
            ) {
                setMenuOpen(false); // Side bar'ı kapat
            }
        };

        if (menuOpen) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick); // Temizle
        };
    }, [menuOpen]);

    return (
        <header>
            <div className="header-container">
            <div className="logo">
    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        <img src="/img/logo.svg" alt="Uygun Psikolog Logo" className="logo-image" />
    </Link>
</div>


                {/* Masaüstü görünümde normal menü */}
                <nav className="header-nav">
                    {currentUser ? (
                        <div className="user-menu">
                            <span className="user-name" onClick={handleProfileClick}>
                                {isAdmin ? currentUser.displayName || 'Admin' : psychologistName || 'Psikolog'}
                            </span>
                            <button className="menu-item" onClick={handleLogout}>Çıkış Yap</button>
                        </div>
                    ) : (
                        <ul>
                            <li><Link to="/about-us">Biz Kimiz</Link></li>
                            <li><Link to="/kendini-test-et">Kendini Test Et</Link></li>
                            <li><Link to="/psychologist">Psikologlar</Link></li>
                            <li><Link to="/psychologist-application">Psikolog Başvurusu</Link></li>
                            <li><Link to="/login">Giriş Yap</Link></li>
                            

                        </ul>
                    )}
                </nav>

                {/* Hamburger Menü İkonu (Sadece mobilde görünür) */}
                <div className="menu-icon" onClick={toggleMenu} ref={menuIconRef}>
                    &#9776; {/* Hamburger ikonu */}
                </div>

                {/* Mobilde açılacak Side Bar Menü */}
                <div className={`side-bar ${menuOpen ? 'active' : ''}`} ref={sideBarRef}>
                    <ul>
                        <li><Link to="/kendini-test-et" onClick={toggleMenu}>Kendini Test Et</Link></li>
                        <li><Link to="/psychologist" onClick={toggleMenu}>Psikologlar</Link></li>
                        <li><Link to="/psychologist-application" onClick={toggleMenu}>Psikolog Başvurusu</Link></li>
                        <li><Link to="/login" onClick={toggleMenu}>Giriş Yap</Link></li>
                        <li><Link to="/about-us" onClick={toggleMenu}>Biz Kimiz</Link></li>

                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Header;
