import React, { useEffect, useState } from 'react';
import { collection, getDocs, deleteDoc, doc ,getDoc} from 'firebase/firestore';
import { db, auth } from '../firebaseConfig'; // auth'i de import edin
import PsychologistCard from '../components/PsychologistCard';
import AddPsychologist from './AddPsychologist'; // Psikolog ekleme bileşeni
import styles from './AdminPanel.module.css'; // CSS modülünü kullanın
import { useNavigate } from 'react-router-dom'; // Yönlendirme için import edin

function AdminPanel() {
    const [psychologists, setPsychologists] = useState([]);
    const [applications, setApplications] = useState([]); // Başvurular için state
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAddPsychologist, setShowAddPsychologist] = useState(false);
    const [showApplications, setShowApplications] = useState(false); // Başvuruları gösterme durumu
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4; // Sayfada gösterilecek psikolog sayısı

    const navigate = useNavigate(); // Yönlendirme için useNavigate kullanın

    useEffect(() => {
        // Kullanıcı giriş kontrolü
        const user = auth.currentUser;
        if (!user) {
            navigate('/login'); // Eğer kullanıcı yoksa giriş sayfasına yönlendir
        } else {
            const checkUserRole = async () => {
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);
                
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    if (userData.role !== 'admin') {
                        navigate('/login'); // Eğer admin değilse giriş sayfasına yönlendir
                    }
                } else {
                    navigate('/login'); // Kullanıcı belgesi yoksa giriş sayfasına yönlendir
                }
            };

            checkUserRole(); // Kullanıcı rolünü kontrol et
        }
    }, [navigate]);

    useEffect(() => {
        const fetchPsychologists = async () => {
            try {
                const snapshot = await getDocs(collection(db, 'psychologists'));
                const psychologistsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                setPsychologists(psychologistsData);
                setLoading(false);
            } catch (err) {
                setError('Veriler alınırken bir hata oluştu.');
                setLoading(false);
            }
        };

        const fetchApplications = async () => {
            try {
                const snapshot = await getDocs(collection(db, 'psychologistApplications'));
                const applicationsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                setApplications(applicationsData);
            } catch (err) {
                setError('Başvurular alınırken bir hata oluştu.');
            }
        };

        fetchPsychologists();
        fetchApplications();
    }, []);

    const toggleAddPsychologist = () => {
        setShowAddPsychologist(prev => !prev);
        setShowApplications(false); // Başvurular sekmesini kapat
    };

    const toggleApplications = () => {
        setShowApplications(prev => !prev);
        setShowAddPsychologist(false); // Psikolog ekleme sekmesini kapat
    };

    const handleRejectApplication = async (applicationId) => {
        if (window.confirm('Bu başvuruyu reddetmek istediğinize emin misiniz?')) {
            try {
                // Firestore'dan başvuruyu sil
                await deleteDoc(doc(db, 'psychologistApplications', applicationId));
                
                // Firestore'dan başvuru listesine yeniden yükle
                const updatedApplications = applications.filter(app => app.id !== applicationId);
                setApplications(updatedApplications);

                alert('Başvuru reddedildi.');
            } catch (error) {
                console.error('Başvuru reddetme hatası:', error);
                alert('Başvuru reddedilirken bir hata oluştu.');
            }
        }
    };

    // Sayfalandırma için psikologları al
    const indexOfLastPsychologist = currentPage * itemsPerPage;
    const indexOfFirstPsychologist = indexOfLastPsychologist - itemsPerPage;
    const currentPsychologists = psychologists.slice(indexOfFirstPsychologist, indexOfLastPsychologist);

    // Sayfa değişimini yönetme
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className={styles.adminPanel}>
            <div className={styles.adminSidebar}>
                <h2>Admin Paneli</h2>
                <ul>
                    <li onClick={toggleAddPsychologist} style={{ cursor: 'pointer' }}>
                        Psikolog Ekle
                    </li>
                    <li onClick={toggleApplications} style={{ cursor: 'pointer' }}>
                        Başvurular
                    </li>
                    <li onClick={() => setShowAddPsychologist(false) || setShowApplications(false)} style={{ cursor: 'pointer' }}>
                        Psikologlar
                    </li>
                </ul>
            </div>
            <div className={styles.adminContent}>
                {showAddPsychologist ? (
                    <AddPsychologist />
                ) : showApplications ? (
                    <>
                        <h2>Başvurular</h2>
                        <div className={styles.applicationList}>
                            {applications.length > 0 ? (
                                applications.map((application) => (
                                    <div key={application.id} className={styles.applicationCard}>
                                        <h4>{application.name}</h4>
                                        <p>Şehir: {application.city}</p>
                                        <p>Çalışma Alanları: {application.specialties.join(', ')}</p>
                                        <p>Deneyim: {application.experience}</p>
                                        <a href={application.resume} target="_blank" rel="noopener noreferrer">Özgeçmişi Görüntüle</a>
                                        <a href={application.diploma} target="_blank" rel="noopener noreferrer">Diplomayı Görüntüle</a>
                                        <button onClick={() => handleRejectApplication(application.id)}>Reddet</button>
                                    </div>
                                ))
                            ) : (
                                <p>Başvuru bulunamadı.</p>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <h2>Uzman Psikologlar</h2>
                        <div className={styles.psychologistList}>
                            {loading ? (
                                <p>Yükleniyor...</p>
                            ) : error ? (
                                <p>{error}</p>
                            ) : currentPsychologists.length > 0 ? (
                                currentPsychologists.map((psychologist) => (
                                    <div key={psychologist.id} className={styles.adminPsychologistCard}>
                                        <PsychologistCard
                                            imgSrc={psychologist.imgSrc || 'https://via.placeholder.com/150'}
                                            name={psychologist.name}
                                            specialty={psychologist.specialties ? psychologist.specialties.join(', ') : ''}
                                            bio={psychologist.bio}
                                            price={psychologist.price}
                                        />
                                    </div>
                                ))
                            ) : (
                                <p>Uzman psikolog bulunamadı.</p>
                            )}
                        </div>

                        {/* Sayfalandırma Kontrolleri */}
                        <div className={styles.pagination}>
                            {Array.from({ length: Math.ceil(psychologists.length / itemsPerPage) }, (_, index) => (
                                <button key={index + 1} onClick={() => paginate(index + 1)} className={styles.pageButton}>
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default AdminPanel;
