import React, { useState } from 'react';
import styles from './ArizonaSexualExperienceScaleWomen.module.css'; // Module CSS import

function ArizonaSexualExperienceScaleWomen() {
  const [answers, setAnswers] = useState(Array(5).fill(0));

  const questions = [
    'Cinsel açıdan ne derece isteklisiniz?',
    'Cinsel açıdan ne kadar kolay uyarılırsınız (tahrik olursunuz)?',
    'Vajinanız/cinsel organınız ilişki sırasında ne kadar kolay ıslanır veya nemlenir?',
    'Ne kadar kolay orgazm olursunuz?',
    'Orgazmınız tatmin edici midir?'
  ];

  const options = [
    '1 - Oldukça istekli / kolay',
    '2 - Çok istekli / kolay',
    '3 - Biraz istekli / kolay',
    '4 - Biraz isteksiz / zor',
    '5 - Çok isteksiz / zor',
    '6 - Tamamen isteksiz / zor / asla olmaz'
  ];

  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const calculateTotalScore = () => {
    return answers.reduce((acc, curr) => acc + parseInt(curr), 0);
  };

  return (
    <div className={styles.testContainer}>
      <div className={styles.testHeader}>
        <h1>Arizona Cinsel Yaşantılar Ölçeği (ACYÖ) - Kadın Formu</h1>
      </div>
      <p className={styles.testDescription}>
        Lütfen her madde için BUGÜN de dahil GEÇEN HAFTAKİ durumunuzu işaretleyin:
      </p>

      {questions.map((question, index) => (
        <div key={index} className={styles.question}>
          <p>{question}</p>
          {options.map((option, optionIndex) => (
            <label key={optionIndex} className={styles.option}>
              <input
                type="radio"
                name={`question-${index}`}
                value={optionIndex + 1}
                checked={answers[index] === optionIndex + 1}
                onChange={() => handleChange(index, optionIndex + 1)}
              />
              {option}
            </label>
          ))}
        </div>
      ))}

      <h2 className={styles.totalScore}>Toplam ACYÖ Skoru: {calculateTotalScore()}</h2>
    </div>
  );
}

export default ArizonaSexualExperienceScaleWomen;
