import { auth, db } from '../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getDoc, doc } from 'firebase/firestore';

export const checkPsychologistLogin = async (email, password) => {
  try {
    // Firebase Authentication ile giriş yap
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Firestore'dan psikolog bilgilerini kontrol et
    const psychologistDocRef = doc(db, 'psychologists', user.uid); // UID ile belgeyi getir
    const psychologistDoc = await getDoc(psychologistDocRef);

    if (psychologistDoc.exists()) {
      console.log("Psikolog bilgileri:", psychologistDoc.data()); // Giriş başarılı, bilgileri kontrol et
      return true;
    } else {
      console.error("Psikolog kaydı bulunamadı.");
      return false;
    }
  } catch (error) {
    console.error('Psikolog giriş hatası:', error);
    return false; // Giriş başarısız
  }
};

export default checkPsychologistLogin;
