import React, { useState } from 'react';
import styles from './AppointmentFlow.module.css';

function AppointmentTypeModal({ onSelectType }) {
  const [selectedType, setSelectedType] = useState(null);

  // Fiyat bilgisini ekleyerek seçilen türü üst bileşene gönderiyoruz
  const handleSelectType = (type, price) => {
    setSelectedType(type);
    onSelectType({ type, price }); // type ve price bilgisi iletin
  };

  return (
    <div className={styles['appointment-flow-content']}>
      <h2>Randevu Türünü Seçin</h2>
      <div className={styles['appointment-card-container']}>

        {/* 1 Seans */}
        <div
          className={`${styles['appointment-card']} ${selectedType === '1 seans' ? styles.selected : ''}`}
          onClick={() => handleSelectType('1 seans', 1099)}
        >
          <h3>1 Seans</h3>
          <p>Seans başına 1099 TL</p>
        </div>

        {/* 2 Seans */}
        <div
          className={`${styles['appointment-card']} ${selectedType === '2 seans' ? styles.selected : ''}`}
          onClick={() => handleSelectType('2 seans', 1900)}
        >
          <h3>2 Seans</h3>
          <p>Seans başına 950 TL</p>
          <p>TOPLAM: 1900 TL</p>
          <span className={styles['popular-badge']}>Popüler</span>
        </div>

        {/* 3 Seans */}
        <div
          className={`${styles['appointment-card']} ${selectedType === '3 seans' ? styles.selected : ''}`}
          onClick={() => handleSelectType('3 seans', 3000)}
        >
          <h3>3 Seans</h3>
          <p>Seans başına 1000 TL</p>
          <p>TOPLAM: 3000 TL</p>
        </div>

        {/* 4 Seans */}
        <div
          className={`${styles['appointment-card']} ${selectedType === '4 seans' ? styles.selected : ''}`}
          onClick={() => handleSelectType('4 seans', 3600)}
        >
          <h3>4 Seans</h3>
          <p>Seans başına 900 TL</p>
          <p>TOPLAM: 3600 TL</p>
        </div>
      </div>
    </div>
  );
}

export default AppointmentTypeModal;
