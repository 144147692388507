import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage } from '../firebaseConfig'; // Firebase Storage eklendi
import { doc, getDoc, updateDoc, collection, addDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Resim yükleme için gerekli metotlar
import styles from './PsychologistDashboard.module.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule';
import multiMonthPlugin from '@fullcalendar/multimonth';
import AvailabilityModal from '../components/AvailabilityModal';
import EventDetailModal from '../components/EventDetailModal';
import AppointmentsList from '../components/AppointmentsList'; // Randevular Listesi bileşenini ekleyin

function PsychologistDashboard() {
  const [psychologistData, setPsychologistData] = useState({});
  const [activeTab, setActiveTab] = useState('bilgilerim'); // Varsayılan sekme
  const [events, setEvents] = useState([]);
  const [selectedEventTime, setSelectedEventTime] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEventDetailModalOpen, setIsEventDetailModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [imageUpload, setImageUpload] = useState(null); // Resim dosyasını tutacak state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPsychologistData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'psychologists', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPsychologistData(docSnap.data());
          
          // Etkinlikleri yükle
          const eventsRef = collection(db, 'psychologists', user.uid, 'events');
          const eventsSnapshot = await getDocs(eventsRef);
          const eventsData = eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setEvents(eventsData);
        } else {
          alert('Bilgileriniz bulunamadı.');
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    };
    fetchPsychologistData();
  }, [navigate]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const user = auth.currentUser;
      const docRef = doc(db, 'psychologists', user.uid);
      await updateDoc(docRef, psychologistData);
      alert('Bilgiler başarıyla güncellendi!');
    } catch (error) {
      console.error('Güncelleme hatası:', error);
      alert('Güncelleme sırasında bir hata oluştu.');
    }
  };

  // Profil resmi değiştirme işlemi
  const handleImageUpload = async () => {
    if (imageUpload == null) return;
    try {
      const user = auth.currentUser;
      const imageRef = ref(storage, `profileImages/${user.uid}`); // Firebase Storage referansı
      await uploadBytes(imageRef, imageUpload); // Dosyayı yükleyin
      const downloadURL = await getDownloadURL(imageRef); // Yüklenen dosyanın URL'sini alın
      
      // Firestore'da profil resmi URL'sini güncelleyin
      const docRef = doc(db, 'psychologists', user.uid);
      await updateDoc(docRef, { imgSrc: downloadURL });

      setPsychologistData((prevData) => ({ ...prevData, imgSrc: downloadURL })); // UI'ı güncelle
      alert('Profil resmi başarıyla güncellendi!');
    } catch (error) {
      console.error('Resim yükleme hatası:', error);
      alert('Resim yüklenirken bir hata oluştu.');
    }
  };

  // Güne tıklayınca modal açılacak ve saat seçimi yapılacak
  const handleDateSelect = (selectInfo) => {
    setSelectedEventTime({
      start: selectInfo.startStr,
      end: selectInfo.endStr,
    });
    setIsModalOpen(true); // Modal'ı aç
    selectInfo.view.calendar.unselect(); // Seçimi temizle
  };

  // Modal'dan gelen saatleri kaydetme işlemi
  const handleSaveAvailability = async ({ title }) => {
    const user = auth.currentUser;
    const newEvent = {
      title: title,
      start: selectedEventTime.start,
      end: selectedEventTime.end,
      createdAt: new Date(),
    };

    // Kullanıcının events koleksiyonuna yeni etkinlik ekleyin
    const eventRef = await addDoc(collection(db, 'psychologists', user.uid, 'events'), newEvent);
    newEvent.id = eventRef.id; // Etkinliğin ID'sini ekleyin
    setEvents([...events, newEvent]); // Takvimi güncelle
    setIsModalOpen(false); // Modal'ı kapat
  };

  const handleEventClick = async (clickInfo) => {
    setSelectedEvent(clickInfo.event); // Seçilen etkinliği ayarla
    setIsEventDetailModalOpen(true); // Detayları gösteren modalı aç
  };

  const handleEventDetailClose = () => {
    setIsEventDetailModalOpen(false); // Detay modalını kapat
  };

  const handleEventDrop = async (eventDropInfo) => {
    const user = auth.currentUser;
    const eventId = eventDropInfo.event.id;
    const updatedEvent = {
      title: eventDropInfo.event.title,
      start: eventDropInfo.event.startStr,
      end: eventDropInfo.event.endStr,
    };

    // Firestore'daki etkinliği güncelle
    const eventRef = doc(db, 'psychologists', user.uid, 'events', eventId);
    await updateDoc(eventRef, updatedEvent);
    
    // Takvimdeki etkinlikleri güncelle
    const updatedEvents = events.map(event => 
      event.id === eventId ? { ...event, ...updatedEvent } : event
    );
    setEvents(updatedEvents);
  };

  const handleEventDelete = async (event) => {
    const user = auth.currentUser;

    if (window.confirm(`Etkinliği silmek istediğinizden emin misiniz? '${event.title}'`)) {
      // Silinen etkinliği Firestore'dan silin
      const eventRef = doc(db, 'psychologists', user.uid, 'events', event.id);
      await deleteDoc(eventRef);

      // Takvimden etkinliği kaldır
      const updatedEvents = events.filter(e => e.id !== event.id);
      setEvents(updatedEvents); // Güncellenmiş etkinlikleri ayarla
    }
  };

  // Yenileme butonu işlevi
  const handleRefresh = async () => {
    const user = auth.currentUser;
    const eventsRef = collection(db, 'psychologists', user.uid, 'events');
    const eventsSnapshot = await getDocs(eventsRef);
    const eventsData = eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setEvents(eventsData);
  };

  return (
    <div className={styles.dashboardContainer}>
      {/* Sidebar Menüsü */}
      <div className={styles.sidebar}>
        <h3 className={styles.sidebarTitle}>Menü</h3>
        <ul className={styles.sidebarMenu}>
          <li
            className={`${styles.sidebarMenuItem} ${activeTab === 'bilgilerim' ? styles.active : ''}`}
            onClick={() => setActiveTab('bilgilerim')}
          >
            Bilgilerim
          </li>
          <li
            className={`${styles.sidebarMenuItem} ${activeTab === 'takvimim' ? styles.active : ''}`}
            onClick={() => setActiveTab('takvimim')}
          >
            Takvimim
            <button onClick={handleRefresh} className={styles.refreshButton}>Yenile</button> {/* Yenileme butonu */}
          </li>
          <li
            className={`${styles.sidebarMenuItem} ${activeTab === 'randevularım' ? styles.active : ''}`}
            onClick={() => setActiveTab('randevularım')}
          >
            Randevularım
          </li>
        </ul>
      </div>

      {/* İçerik Alanı */}
      <div className={styles.content}>
        {activeTab === 'bilgilerim' ? (
          <div className={styles.profileCard}>
            <img
              src={psychologistData.imgSrc || 'https://via.placeholder.com/200'}
              alt="Profile"
              className={styles.profileImage}
            />
            <div className={styles.profileDetails}>
              <h2>{psychologistData.name || 'İsim Yok'}</h2>
              <p>{psychologistData.specialties ? psychologistData.specialties.join(', ') : 'Uzmanlık Alanları Yok'}</p>
              <p>{psychologistData.bio || 'Biyografi Yok'}</p>
              
              {/* Dosya seçimi ve resim güncelle butonu */}
              <input
                type="file"
                onChange={(e) => setImageUpload(e.target.files[0])}
                className={styles.fileInput}
              />
              <button onClick={handleImageUpload} className={styles.updateButton}>
                Resmi Güncelle
              </button>

              <form onSubmit={handleUpdate}>
                <label>İsim</label>
                <input
                  type="text"
                  value={psychologistData.name || ''}
                  onChange={(e) => setPsychologistData({ ...psychologistData, name: e.target.value })}
                  required
                />
                <label>Uzmanlık Alanları</label>
                <input
                  type="text"
                  value={psychologistData.specialties ? psychologistData.specialties.join(', ') : ''}
                  onChange={(e) => {
                    setPsychologistData({ 
                      ...psychologistData, 
                      specialties: e.target.value.split(',').map(item => item.trimStart()) 
                    });
                  }}
                  required
                  placeholder="Çalışma Alanları (örn: Öğrenci Koçluğu, Depresyon, Anksiyete)"
                />
                <label>Biyografi</label>
                <textarea
                  value={psychologistData.bio || ''}
                  onChange={(e) => setPsychologistData({ ...psychologistData, bio: e.target.value })}
                  required
                />
                <label>Fiyat</label>
                <input
                  type="text"
                  value={psychologistData.price || ''}
                  onChange={(e) => setPsychologistData({ ...psychologistData, price: e.target.value })}
                  required
                />
                <button type="submit">Bilgileri Güncelle</button>
              </form>
            </div>
          </div>
        ) : activeTab === 'takvimim' ? (
          <div>
            <h2>Takvimim</h2>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, multiMonthPlugin, rrulePlugin]}
              initialView="dayGridMonth"
              selectable={true}
              editable={true}
              events={events}
              select={handleDateSelect}
              eventClick={handleEventClick}
              eventDrop={handleEventDrop}
              headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth,today' // Today butonunu buraya ekliyoruz
              }}
              allDaySlot={false} // Tüm gün slotunu devre dışı bırak
              timeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false // AM/PM yerine 24 saat formatında
              }}
            />
          </div>
        ) : activeTab === 'randevularım' ? (
          <AppointmentsList psychologistName={psychologistData.name} />
        ) : null}
      </div>

      {/* Seçilen günün saatlerini ayarlamak için modal */}
      {isModalOpen && (
        <AvailabilityModal
          selectedDate={selectedEventTime.start} // Sadece başlangıç tarihini geçiriyoruz
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveAvailability}
        />
      )}

      {/* Etkinlik Detayları Modalı */}
      {isEventDetailModalOpen && (
        <EventDetailModal
          event={selectedEvent} // Seçilen etkinlik
          onClose={handleEventDetailClose}
          onDelete={() => handleEventDelete(selectedEvent)} // Silme işlemi
        />
      )}
    </div>
  );
}

export default PsychologistDashboard;
