import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PsychologistPanel from './pages/PsychologistPanel';
import AdminPanel from './pages/AdminPanel';
import TestSelectionPage from './pages/TestSelectionPage';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import LoginPage from './pages/LoginPage';
import PsychologistDashboard from './pages/PsychologistDashboard';
import { UserProvider } from './UserContext';
import PsychologistApplication from './pages/PsychologistApplication';

import NotFoundPage from './pages/NotFoundPage'; // NotFoundPage'i içe aktar
import AboutUsPage from './pages/AboutUsPage';
import Aydinlatma from './pages/Aydinlatma';
import GizlilikPolitikasi from './pages/GizlilikPolitikasi';
import KVKK from './pages/KVKK';
import CancellationAndRefundPolicy from './pages/CancellationAndRefundPolicy';


// Test bileşenlerini import ediyoruz
import ArizonaSexualExperienceScaleWomen from './tests/ArizonaSexualExperienceScaleWomen';
import ArizonaSexualExperiencesScale from './tests/ArizonaSexualExperiencesScale';
import BeckAnxietyInventory from './tests/BeckAnxietyInventory';
import BeckDepressionInventory from './tests/BeckDepressionInventory';
import BriefPsychiatricRatingScale from './tests/BriefPsychiatricRatingScale';
import PanicDisorderScale from './tests/PanicDisorderScale';


function App() {
  return (
    <UserProvider>
      <div className="app-container">
        <Router>
          <Header />
          <div className="content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/psychologist" element={<PsychologistPanel />} />
              <Route path="/admin" element={<AdminPanel />} />
              <Route path="/kendini-test-et" element={<TestSelectionPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/psychologist-dashboard" element={<PsychologistDashboard />} />
              <Route path="/psychologist-application" element={<PsychologistApplication />} /> 
              <Route path="/kvkk" element={<KVKK />} />
              <Route path="/aydinlatma" element={<Aydinlatma />} />
              <Route path="/gizlilik-politikasi" element={<GizlilikPolitikasi />} />
              <Route path="/about-us" element={<AboutUsPage />} /> {/* Biz Kimiz Sayfası */}
              <Route path="/cancellation-and-refund-policy" element={<CancellationAndRefundPolicy />} /> {/* İptal ve İade Sözleşmesi sayfası */}
              
              
              {/* Test bileşenleri */}
              <Route path="/tests/arizona-women" element={<ArizonaSexualExperienceScaleWomen />} />
              <Route path="/tests/arizona-men" element={<ArizonaSexualExperiencesScale />} />
              <Route path="/tests/anxiety" element={<BeckAnxietyInventory />} />
              <Route path="/tests/depression" element={<BeckDepressionInventory />} />
              <Route path="/tests/brief-psychiatric" element={<BriefPsychiatricRatingScale />} />
              <Route path="/tests/panic" element={<PanicDisorderScale />} />
              
              {/* Geçersiz URL'ler için 404 sayfası */}
              <Route path="*" element={<NotFoundPage />} /> {/* Herhangi bir yol tanımlanmadıysa NotFoundPage'i göster */}
            </Routes>
          </div>
          <Footer />
        </Router>
      </div>
    </UserProvider>
  );
}

export default App;
