import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import PsychologistCard from '../components/PsychologistCard';
import styles from './PsychologistPanel.module.css'; // CSS modülü

function PsychologistPanel() {
    const [psychologists, setPsychologists] = useState([]);
    const [filteredPsychologists, setFilteredPsychologists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedSpecialty, setSelectedSpecialty] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    useEffect(() => {
        const fetchPsychologists = async () => {
            try {
                const snapshot = await getDocs(collection(db, 'psychologists'));
                const psychologistsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                setPsychologists(psychologistsData);
                setFilteredPsychologists(psychologistsData);
                setLoading(false);
            } catch (err) {
                setError('Veriler alınırken bir hata oluştu.');
                setLoading(false);
            }
        };
        fetchPsychologists();
    }, []);

    const handleFilter = async () => {
        let filteredList = psychologists;
    
        if (selectedSpecialty) {
            filteredList = filteredList.filter((psychologist) =>
                psychologist.specialties && psychologist.specialties.includes(selectedSpecialty)
            );
        }
    
        if (selectedDate) {
            // Seçilen tarihte müsait psikologları filtrelemek için:
            try {
                const availablePsychologists = [];
    
                // Her bir psikoloğun "events" koleksiyonunu kontrol et
                for (let psychologist of filteredList) {
                    const eventsCollection = collection(db, `psychologists/${psychologist.id}/events`);
                    const q = query(eventsCollection, where('title', '==', 'müsait'));
                    const snapshot = await getDocs(q);
    
                    if (!snapshot.empty) {
                        // Her bir randevu için 'start' tarihini kontrol et
                        snapshot.docs.forEach((doc) => {
                            const eventData = doc.data();
                            const eventDate = new Date(eventData.start).toISOString().split('T')[0]; // Sadece 'YYYY-MM-DD' kısmını al
                            
                            // Eğer 'start' tarihi seçilen tarih ile eşleşiyorsa
                            if (eventDate === selectedDate) {
                                availablePsychologists.push(psychologist);
                            }
                        });
                    }
                }
    
                filteredList = availablePsychologists;
    
                if (filteredList.length === 0) {
                    setError('Seçilen tarihte müsait psikolog bulunamadı.');
                } else {
                    setError(null);
                }
    
                setFilteredPsychologists(filteredList);
            } catch (err) {
                console.error('Tarihe göre filtreleme hatası:', err);
                setError('Tarihe göre filtreleme sırasında bir hata oluştu.');
            }
        } else {
            setFilteredPsychologists(filteredList); // Eğer tarih seçilmemişse sadece uzmanlık alanına göre filtrele
        }
    };
    
    const handleReset = () => {
        setSelectedSpecialty('');
        setSelectedDate('');
        setFilteredPsychologists(psychologists); // Filtreyi sıfırla
        setError(null);
    };

    return (
        <div className={styles['psychologist-panel']}>
            <h2>Uzman Psikologlar</h2>

            {/* Filtreleme Bölümü */}
            <div className={styles['filter-section']}>
                <div className={styles['filter-item']}>
                    <label htmlFor="date">Randevu Tarihi:</label>
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    />
                </div>
                <div className={styles['filter-item']}>
                    <label htmlFor="specialty">Uzmanlık Alanı:</label>
                    <select
                        value={selectedSpecialty}
                        onChange={(e) => setSelectedSpecialty(e.target.value)}
                    >
                        <option value="">Uzmanlık Alanı Seçin</option>
                        <option value="Bağımlılık Terapisi">Bağımlılık Terapisi</option>
                        <option value="Psikolojik Tramva">Psikolojik Tramva</option>
                        <option value="İlişki & Aile Danışmanlığı">İlişki & Aile Danışmanlığı</option>
                        <option value="Öğrenci Koçluğu">Öğrenci Koçluğu</option>
                        <option value="Sınav Kaygısı">Sınav Kaygısı</option>
                        <option value="Depresyon">Depresyon</option>
                        <option value="Duygu ve Stres Yönetimi">Duygu ve Stres Yönetimi</option>
                        <option value="Kişisel Gelişim">Kişisel Gelişim</option>
                    </select>
                </div>
            </div>

            {/* Butonlar Bölümü */}
            <div className={styles['filter-buttons']}>
                <button onClick={handleFilter}>Filtrele</button>
                <button onClick={handleReset}>Sıfırla</button>
            </div>

            {/* Psikolog Listesi Bölümü */}
            <div className={styles['psychologist-list']}>
                {loading ? (
                    <p>Yükleniyor...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : filteredPsychologists.length > 0 ? (
                    filteredPsychologists.map((psychologist) => (
                        <div key={psychologist.id} className={styles['psychologist-card']}>
                            <PsychologistCard
                                imgSrc={psychologist.imgSrc || "https://via.placeholder.com/150"}
                                name={psychologist.name}
                                specialty={psychologist.specialties ? psychologist.specialties.join(', ') : ""}
                                bio={psychologist.bio}
                                price={psychologist.price}
                            />
                        </div>
                    ))
                ) : (
                    <p>Uzman psikolog bulunamadı.</p>
                )}
            </div>
        </div>
    );
}

export default PsychologistPanel;
