import React, { useState } from 'react';

function BeckDepressionInventory() {
  // Her sorunun cevaplarını tutan state
  const [answers, setAnswers] = useState(Array(21).fill(0));

  // Sorular ve seçenekler
  const questions = [
    {
      question: '1. Kendimi üzüntülü ve sıkıntılı hissetmiyorum.',
      options: [
        '0 = Kendimi üzüntülü ve sıkıntılı hissetmiyorum.',
        '1 = Kendimi üzüntülü ve sıkıntılı hissediyorum.',
        '2 = Hep üzüntülü ve sıkıntılıyım. Bundan kurtulamıyorum.',
        '3 = O kadar üzüntülü ve sıkıntılıyım ki artık dayanamıyorum.'
      ]
    },
    {
      question: '2. Gelecek hakkında mutsuz ve karamsar değilim.',
      options: [
        '0 = Gelecek hakkında mutsuz ve karamsar değilim.',
        '1 = Gelecek hakkında karamsarım.',
        '2 = Gelecekten beklediğim hiçbir şey yok.',
        '3 = Geleceğim hakkında umutsuzum ve sanki hiçbir şey düzelmeyecekmiş gibi geliyor.'
      ]
    },
    {
      question: '3. Kendimi başarısız bir insan olarak görmüyorum.',
      options: [
        '0 = Kendimi başarısız bir insan olarak görmüyorum.',
        '1 = Çevremdeki birçok kişiden daha çok başarısızlıklarım olmuş gibi hissediyorum.',
        '2 = Geçmişe baktığımda başarısızlıklarla dolu olduğunu görüyorum.',
        '3 = Kendimi tümüyle başarısız biri olarak görüyorum.'
      ]
    },
    {
      question: '4. Birçok şeyden eskisi kadar zevk alıyorum.',
      options: [
        '0 = Birçok şeyden eskisi kadar zevk alıyorum.',
        '1 = Eskiden olduğu gibi her şeyden hoşlanmıyorum.',
        '2 = Artık hiçbir şey bana tam anlamıyla zevk vermiyor.',
        '3 = Her şeyden sıkılıyorum.'
      ]
    },
    {
      question: '5. Kendimi herhangi bir şekilde suçlu hissetmiyorum.',
      options: [
        '0 = Kendimi herhangi bir şekilde suçlu hissetmiyorum.',
        '1 = Kendimi zaman zaman suçlu hissediyorum.',
        '2 = Çoğu zaman kendimi suçlu hissediyorum.',
        '3 = Kendimi her zaman suçlu hissediyorum.'
      ]
    },
    {
      question: '6. Bana cezalandırılmışım gibi geliyor.',
      options: [
        '0 = Bana cezalandırılmışım gibi gelmiyor.',
        '1 = Cezalandırılabileceğimi hissediyorum.',
        '2 = Cezalandırılmayı bekliyorum.',
        '3 = Cezalandırıldığımı hissediyorum.'
      ]
    },
    {
      question: '7. Kendimden memnunum.',
      options: [
        '0 = Kendimden memnunum.',
        '1 = Kendi kendimden pek memnun değilim.',
        '2 = Kendime çok kızıyorum.',
        '3 = Kendimden nefret ediyorum.'
      ]
    },
    {
      question: '8. Başkalarından daha kötü olduğumu sanmıyorum.',
      options: [
        '0 = Başkalarından daha kötü olduğumu sanmıyorum.',
        '1 = Zayıf yanlarım veya hatalarım için kendi kendimi eleştiririm.',
        '2 = Hatalarımdan dolayı her zaman kendimi kabahatli bulurum.',
        '3 = Her aksilik karşısında kendimi hatalı bulurum.'
      ]
    },
    {
      question: '9. Kendimi öldürmek gibi düşüncelerim yok.',
      options: [
        '0 = Kendimi öldürmek gibi düşüncelerim yok.',
        '1 = Zaman zaman kendimi öldürmeyi düşündüğüm olur. Fakat yapmıyorum.',
        '2 = Kendimi öldürmek isterdim.',
        '3 = Fırsatını bulsam kendimi öldürürdüm.'
      ]
    },
    {
      question: '10. Her zamankinden fazla içimden ağlamak gelmiyor.',
      options: [
        '0 = Her zamankinden fazla içimden ağlamak gelmiyor.',
        '1 = Zaman zaman içimden ağlamak geliyor.',
        '2 = Çoğu zaman ağlıyorum.',
        '3 = Eskiden ağlayabilirdim şimdi istesem de ağlayamıyorum.'
      ]
    },
    {
      question: '11. Şimdi her zaman olduğumdan daha sinirli değilim.',
      options: [
        '0 = Şimdi her zaman olduğumdan daha sinirli değilim.',
        '1 = Eskisine kıyasla daha kolay kızıyor ya da sinirleniyorum.',
        '2 = Şimdi hep sinirliyim.',
        '3 = Bir zamanlar beni sinirlendiren şeyler şimdi hiç sinirlendirmiyor.'
      ]
    },
    {
      question: '12. Başkaları ile görüşmek, konuşmak isteğimi kaybetmedim.',
      options: [
        '0 = Başkaları ile görüşmek, konuşmak isteğimi kaybetmedim.',
        '1 = Başkaları ile eskiden daha az konuşmak, görüşmek istiyorum.',
        '2 = Başkaları ile konuşma ve görüşme isteğimi kaybettim.',
        '3 = Hiç kimseyle konuşmak görüşmek istemiyorum.'
      ]
    },
    {
      question: '13. Eskiden olduğu gibi kolay karar verebiliyorum.',
      options: [
        '0 = Eskiden olduğu gibi kolay karar verebiliyorum.',
        '1 = Eskiden olduğu kadar kolay karar veremiyorum.',
        '2 = Karar verirken eskisine kıyasla çok güçlük çekiyorum.',
        '3 = Artık hiç karar veremiyorum.'
      ]
    },
    {
      question: '14. Aynada kendime baktığımda değişiklik görmüyorum.',
      options: [
        '0 = Aynada kendime baktığımda değişiklik görmüyorum.',
        '1 = Daha yaşlanmış ve çirkinleşmişim gibi geliyor.',
        '2 = Görünüşümün çok değiştiğini ve çirkinleştiğimi hissediyorum.',
        '3 = Kendimi çok çirkin buluyorum.'
      ]
    },
    {
      question: '15. Eskisi kadar iyi çalışabiliyorum.',
      options: [
        '0 = Eskisi kadar iyi çalışabiliyorum.',
        '1 = Bir şeyler yapabilmek için gayret göstermem gerekiyor.',
        '2 = Herhangi bir şeyi yapabilmek için kendimi çok zorlamam gerekiyor.',
        '3 = Hiçbir şey yapamıyorum.'
      ]
    },
    {
      question: '16. Her zamanki gibi iyi uyuyabiliyorum.',
      options: [
        '0 = Her zamanki gibi iyi uyuyabiliyorum.',
        '1 = Eskiden olduğu gibi iyi uyuyamıyorum.',
        '2 = Her zamankinden 1-2 saat daha erken uyanıyorum ve tekrar uyuyamıyorum.',
        '3 = Her zamankinden çok daha erken uyanıyor ve tekrar uyuyamıyorum.'
      ]
    },
    {
      question: '17. Her zamankinden daha çabuk yorulmuyorum.',
      options: [
        '0 = Her zamankinden daha çabuk yorulmuyorum.',
        '1 = Her zamankinden daha çabuk yoruluyorum.',
        '2 = Yaptığım her şey beni yoruyor.',
        '3 = Kendimi hemen hiçbir şey yapamayacak kadar yorgun hissediyorum.'
      ]
    },
    {
      question: '18. İştahım her zamanki gibi.',
      options: [
        '0 = İştahım her zamanki gibi.',
        '1 = İştahım her zamanki kadar iyi değil.',
        '2 = İştahım çok azaldı.',
        '3 = Artık hiç iştahım yok.'
      ]
    },
    {
      question: '19. Son zamanlarda kilo vermedim.',
      options: [
        '0 = Son zamanlarda kilo vermedim.',
        '1 = İki kilodan fazla kilo verdim.',
        '2 = Dört kilodan fazla kilo verdim.',
        '3 = Altı kilodan fazla kilo vermeye çalışıyorum.'
      ]
    },
    {
      question: '20. Sağlığım beni fazla endişelendirmiyor.',
      options: [
        '0 = Sağlığım beni fazla endişelendirmiyor.',
        '1 = Ağrı, sancı, mide bozukluğu veya kabızlık gibi rahatsızlıklar beni endişelendirmiyor.',
        '2 = Sağlığım beni endişelendirdiği için başka şeyleri düşünmek zorlaşıyor.',
        '3 = Sağlığım hakkında o kadar endişeliyim ki başka hiçbir şey düşünemiyorum.'
      ]
    },
    {
      question: '21. Son zamanlarda cinsel konulara olan ilgimde bir değişme fark etmedim.',
      options: [
        '0 = Son zamanlarda cinsel konulara olan ilgimde bir değişme fark etmedim.',
        '1 = Cinsel konularla eskisinden daha az ilgiliyim.',
        '2 = Cinsel konularla şimdi çok daha az ilgiliyim.',
        '3 = Cinsel konulara olan ilgimi tamamen kaybettim.'
      ]
    }
  ];

  // Cevapları değiştiren fonksiyon
  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  // Toplam puanı hesaplayan fonksiyon
  const calculateTotalScore = () => {
    return answers.reduce((acc, curr) => acc + parseInt(curr), 0);
  };

  // Depresyon derecesini hesaplayan fonksiyon
  const getDepressionLevel = (totalScore) => {
    if (totalScore <= 9) {
      return 'Minimal depresyon';
    } else if (totalScore <= 16) {
      return 'Hafif depresyon';
    } else if (totalScore <= 29) {
      return 'Orta depresyon';
    } else {
      return 'Şiddetli depresyon';
    }
  };

  return (
    <div>
      <h1>Beck Depresyon Envanteri (BDI)</h1>
      <p>Aşağıdaki soruları cevaplayarak bugünkü ruh halinizi değerlendirin:</p>
      {questions.map((questionObj, index) => (
        <div key={index} style={{ marginBottom: '20px' }}>
          <p>{questionObj.question}</p>
          {questionObj.options.map((option, optionIndex) => (
            <label key={optionIndex} style={{ display: 'block', margin: '5px 0' }}>
              <input
                type="radio"
                name={`question-${index}`}
                value={optionIndex}
                checked={answers[index] === optionIndex}
                onChange={() => handleChange(index, optionIndex)}
              />
              {option}
            </label>
          ))}
        </div>
      ))}

      <h2>Toplam Puan: {calculateTotalScore()}</h2>
      <h3>Depresyon Seviyesi: {getDepressionLevel(calculateTotalScore())}</h3>
    </div>
  );
}

export default BeckDepressionInventory;
