import React from 'react';
import { Link } from 'react-router-dom';
import styles from './TestSelectionPage.module.css';

function TestSelectionPage() {
  return (
    <div className={styles.testSelectionPage}>
      <h1 className={styles.title}>Kendini Test Et</h1>
      <p className={styles.description}>Kendi ruhsal sağlığınızı keşfetmek için bir test seçin:</p>
      
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <Link to="/tests/depression" className={styles.cardLink}>Beck Depresyon Envanteri</Link>
        </div>
        <div className={styles.card}>
          <Link to="/tests/anxiety" className={styles.cardLink}>Beck Anksiyete Ölçeği</Link>
        </div>
        <div className={styles.card}>
          <Link to="/tests/panic" className={styles.cardLink}>Panik Bozukluğu Şiddeti Ölçeği (PBŞÖ)</Link>
        </div>
        <div className={styles.card}>
          <Link to="/tests/arizona-men" className={styles.cardLink}>Arizona Cinsel Yaşantılar Ölçeği (Erkek Formu)</Link>
        </div>
        <div className={styles.card}>
          <Link to="/tests/arizona-women" className={styles.cardLink}>Arizona Cinsel Yaşantılar Ölçeği (Kadın Formu)</Link>
        </div>
        <div className={styles.card}>
          <Link to="/tests/brief-psychiatric" className={styles.cardLink}>Kısa Psikiyatrik Değerlendirme Ölçeği (KPDE)</Link>
        </div>
      </div>
    </div>
  );
}

export default TestSelectionPage;
