import React from 'react';
import styles from './KVKK.module.css';

function KVKK() {
  return (
    <div className={styles.kvkkContainer}>
      <h1>Kişisel Verilerin Korunması Kanunu Çerçevesinde Bilgilendirme Metni</h1>

      <p>Değerli Müşterilerimiz,</p>
      <p>
        Uygun Psikolog olarak 6698 sayılı “Kişisel Verilerin Korunması Kanunu” kapsamında,
        uygulamalarımız ve haklarınız ile ilgili sizleri bilgilendirmek isteriz. 6698 sayılı Kişisel Verilerin
        Korunması Kanunu hükümleri ve ilgili düzenlemeler gereği (“KVKK Düzenlemeleri”) kimliğinizi
        belirli veya belirlenebilir kılan her türlü bilginiz, özel nitelikli kişisel verileriniz de dahil olmak
        üzere, Kişisel Veri olarak nitelendirilmekte ve Veri Sorumlusu sıfatıyla Uygun Psikolog tarafından
        aşağıda açıklandığı kapsamda ve mevzuat tarafından emredilen sınırlar çerçevesinde
        işlenmektedir.
      </p>
      <p>
        “Kişisel Verilerinizin İşlenmesi” bu verilerin elde edilmesi, kaydedilmesi, depolanması,
        muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması,
        elde edilebilir hale getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler
        üzerinde gerçekleştirilen her türlü işlemi ifade etmektedir.
      </p>

      <h2>1. Kişisel Verilerinizin İşlenme Amacı, İşlenen Kişisel Verileriniz</h2>
      <p>
        Tarafınıza ait kişisel bilgiler, onay ve/veya imzanızla tanzim edilen tüm sözleşmeler/bilgilendirme
        formları ve sair belgelerle, elektronik onay ve/veya imzanız ile yapacağınız bildirimlerle, Genel
        Müdürlüğümüz, fiziki ortamlar, çağrı merkezleri, internet siteleri, mobil uygulamalar, internet
        işlemleri, sosyal medya ve diğer kamuya açık mecralar, kullanıcı görüşmeleri, adli kayıtların
        taranması, piyasa istihbaratı, Kimlik Paylaşım Sistemi, SMS kanalları, internet sitelerine yapılan
        dijital başvurular, tüm mobil uygulamalarımız, satış ekiplerine yapılan yazılı/dijital başvurular,
        çağrı merkezi hizmeti alınan şirketler gibi yöntemler ile Sizinle iletişime geçilen veya ileride iletişime
        geçebilecek sözlü, yazılı, görüntülü, ses kaydı veya elektronik kanallar yoluyla tamamen veya
        kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olarak toplanmakta ve
        Kanunun belirtilen hukuka uygunluk sebepleri çerçevesinde, sözleşmenin ifası ve yasal
        yükümlülüklerin yerine getirilmesi amacıyla saklanmaktadır.
      </p>

      <h2>2. Kişisel Verilerin İşlenmesinde Hukuki Sebepler ve Rıza</h2>
      <p>
        Şayet daha önceden alınmış rıza ve izniniz mevcut değilse, yasal zorunluluk gereği izninizi
        talep edeceğimiz durumlarda, web sitemizde yer alan kişisel verileriniz ile ilgili izin alanında veya
        üyelik sayfasında web sitesinde izin kutucuklarının işaretlenmesi ve onaylanması suretiyle izin
        verebileceksiniz. Bu kapsamda, Uygun Psikolog tarafından sizlere yönelik sağlanacak ürün ve
        hizmet avantajlarından yararlanma ve haberdar olma iradenizi ortaya koyarak Uygun Psikolog ile
        paylaştığınız kişisel verilerinizin, sizlere çeşitli avantajların sağlanıp sunulabilmesi ve özel reklam,
        satış, pazarlama, anket ve benzer amaçlı her türlü fiziksel ve elektronik iletişim yapılması ve
        diğer iletişim mesajlarının gönderilmesi amacıyla; toplanmasına, saklanmasına, işlenmesine,
        kullanılmasına, aktarımına dair de izniniz talep edilmektedir. İzinlerinizi geri almak için her zaman
        izin verdiğiniz yöntemlerle Uygun Psikolog’a başvurabilirsiniz.
      </p>

      <h2>3. Kişisel Verilerin Paylaşımı ve Aktarımı</h2>
      <p>
        Uygun Psikolog tarafından işlenen kişisel verileriniz, mevzuatta belirlenen güvenlik ve gizlilik
        esasları uyarınca yeterli ve etkili önlemler alınmak kaydıyla, yasal olarak aktarılması gereken
        idari ve resmi makamlara, faaliyetlerimizi yürütmek üzere dışarıdan hizmet alınan üçüncü
        taraflara, işbirliği yaptığımız, program ortağı kurum, kuruluş, işbirliği yapılan yurtiçi/yurtdışı
        kuruluşlara, hizmet/destek/danışmanlık alınan ya da proje/program ortağı olunan
        yurtiçi/yurtdışı/uluslararası kuruluşlar, güvenlik, çağrı merkezi gibi alanlarda destek aldığımız
        üçüncü kişilerle, alt yüklenicilere, bağımsız denetim ve destek hizmeti alınan kuruluşlar gibi tüzel
        kişilere ve işbirliği içerisinde olan üçüncü tarafların hukuki ve ticari güvenliğinin temini ve/veya
        elektronik ve fiziksel ortamların güvenliğinin sağlanması ile hukuki zorunluluklar nedeniyle ve
        yasal sınırlamalar çerçevesinde aktarılabilecektir.
      </p>
      <p>
        <strong>Tarafımızca, yurt dışına aktarılabilecek kişisel verileriniz aşağıdaki gibidir:</strong>
        <ul>
          <li>Kimlik Verisi: Adı, Soyadı</li>
          <li>İletişim Verisi: E-posta, Telefon, Adres</li>
          <li>Görsel ve İşitsel Veri: Gerçek kişiye ait fotoğraf</li>
          <li>Diğer: Erişim tarih ve saati, Cihaz kimliği, İşletim sistemi, Tarayıcı tipi ve IP adresi</li>
        </ul>
      </p>

      <h2>4. Kanun Kapsamındaki Haklarınız</h2>
      <p>
        Kişisel veri sahipleri olarak şirketin aşağıda bildirilen adresine şahsen başvuru ile ya da noter
        aracılığıyla veya hesap açılışı sırasında bildirdiğiniz ve Uygun Psikolog sisteminde kayıtlı bulunan
        elektronik posta adresinizi kullanmak suretiyle aşağıda bildirilen elektronik posta adresine
        iletebilirsiniz. Şirketimize ileteceğiniz başvurularınızın Türkçe olması esas olup, ilgili
        başvurularınızda ad, soyad, T.C. kimlik numarası, tebligata esas yerleşim yeriniz veya iş yeri
        adresiniz, bildirime esas elektronik posta adresiniz ile talep konunuzu bulunması zorunlu olup,
        talebinize ilişkin bilgi ve belgelerin de başvurunuza eklenmesi gerekmektedir. Başvurunuz yazılı
        ise imza atmanız da yasal bir zorunluluktur. Uygun Psikolog talebin niteliğine göre talebi en kısa
        sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, işlemin ayrıca bir
        maliyeti gerektirmesi hâlinde, Uygun Psikolog tarafından Kişisel Verileri Koruma Kurulu’nca
        belirlenen tarifedeki ücret alınacaktır.
      </p>

      <p className={styles.signature}>Saygılarımızla,</p>
    </div>
  );
}

export default KVKK;
