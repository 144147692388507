import React from 'react';
import styles from './Aydinlatma.module.css';

function Aydinlatma() {
  return (
    <div className={styles.aydinlatmaContainer}>
      <h1>Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni</h1>
      <p>
        İş bu Aydınlatma Metni, <strong>www.uygunpsikolog.com</strong> (bundan sonra "site" olarak anılacaktır) internet
        sitesi ile ilgili olarak, Kişisel Veri Sahipleri’nden ve/veya üçüncü kişilerden elde edilen kişisel verilerin
        kullanımına dair 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") uyarınca veri sorumlusunun, Uygun Psikolog
        olduğunu belirtmek amacıyla hazırlanmıştır.
      </p>
      <p>
        Kişisel verileriniz, veri sorumlusunun belirttiği amaçlar doğrultusunda aşağıda belirtilen kapsamda toplanacak ve
        işlenecektir.
      </p>

      <h2>1. Tanımlar ve Kısaltmalar</h2>
      <ul>
        <li><strong>Şirket:</strong> Aygün Psikolojik Danışmanlık</li>
        <li><strong>Kişisel Veri:</strong> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.</li>
        <li><strong>Kişisel Veri Sahibi:</strong> Kişisel verisi işlenen gerçek kişi.</li>
        <li><strong>Veri Sorumlusu:</strong> Kişisel Verilerin işleme amaçlarını belirleyen ve veri kayıt sistemini yöneten kişi.</li>
        <li><strong>İnternet Sitesi/Site:</strong> www.uygunpsikolog.com</li>
      </ul>

      <h2>2. İşlenen Kişisel Veriler</h2>
      <ul>
        <li><strong>Kimlik Verileri:</strong> Ad, soyad bilgileri.</li>
        <li><strong>İletişim Verileri:</strong> Adres, telefon numarası, e-posta.</li>
        <li><strong>Finans Verileri:</strong> Kredi kartı bilgileri, banka bilgileri.</li>
        <li><strong>Çerez Bilgileri:</strong> Site içi davranışlar, IP adresi.</li>
        <li><strong>Görsel ve İşitsel Kayıtlar:</strong> Online görüşme kayıtları (açık rıza ile).</li>
        <li><strong>İşlem Güvenliği Verisi:</strong> Kullanıcı oturum bilgileri.</li>
      </ul>

      <h2>3. Kişisel Verilerin İşlenme Amacı</h2>
      <p>
        Kişisel verileriniz, ürün ve hizmetlerin sunulması, müşteri ilişkileri yönetimi, pazarlama faaliyetleri, hukuki
        yükümlülüklerin yerine getirilmesi ve hizmet kalitesinin artırılması amacıyla işlenmektedir.
      </p>

      <h2>4. Kişisel Verilerin Toplanma Yöntemleri</h2>
      <p>
        Kişisel veriler, internet sitesi, e-posta, çağrı merkezi gibi kanallarla toplanmaktadır.
      </p>

      <h2>5. Kişisel Verilerin Aktarılması</h2>
      <p>
        Kişisel verileriniz, yasal yükümlülükler, hizmet sağlama ve müşteri memnuniyeti amacıyla yalnızca gerekli üçüncü kişilere
        aktarılmaktadır.
      </p>

      <h2>6. Kişisel Verilerinizin Güvenliği</h2>
      <p>
        Kişisel verilerinizi korumak amacıyla Şirket, ilgili gizlilik ve veri güvenliği yasaları ile yönetmeliklerine uygun tedbirler
        almaktadır. Bu tedbirler, teknolojik gelişmeler, uygulama masrafları ve korunacak verilerin içeriği göz önünde bulundurularak;
        imha, kayıp, değişiklik, izinsiz açıklama veya izinsiz erişim gibi riskleri önlemek için teknik ve kurumsal önlemler
        içermektedir.
      </p>

      <h2>7. Kişisel Veri Sahibinin Hak ve Yükümlülükleri</h2>
      <ul>
        <li>Kişisel verilerinin işlenip işlenmediğini öğrenme.</li>
        <li>Kişisel verileri işlenmişse, buna ilişkin bilgi talep etme.</li>
        <li>Kişisel verilerin işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme.</li>
        <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme.</li>
        <li>Kişisel verilerin eksik veya yanlış işlenmiş olması durumunda düzeltilmesini isteme ve bu işlemin kişisel verilerin
          aktarıldığı üçüncü kişilere bildirilmesini isteme.</li>
        <li>6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
          ortadan kalkması halinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu işlemin kişisel verilerin
          aktarıldığı üçüncü kişilere bildirilmesini isteme.</li>
        <li>İşlenen verilerin yalnızca otomatik sistemler aracılığıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
          ortaya çıkmasına itiraz etme.</li>
        <li>Kişisel verilerin kanuna aykırı olarak işlenmesi nedeniyle zarara uğraması halinde zararın giderilmesini talep etme.</li>
      </ul>

      <h2>İletişim Bilgileri:</h2>
      <p>Uygun Psikolog<br />
        Adres: Bayır Mah. Atatürk Cad. No:148 D:13 Armutlu/YALOVA<br />
        E-posta: destek@uygunpsikolog.com
      </p>

      <p>Bu metin, kişisel verilerinizin işlenmesine ilişkin bilgilendirme yapmak amacıyla hazırlanmıştır. Herhangi bir sorunuz için
        yukarıdaki iletişim bilgilerini kullanabilirsiniz.
      </p>
    </div>
  );
}

export default Aydinlatma;
