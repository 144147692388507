import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import PsychologistCard from '../components/PsychologistCard';
import AppointmentModal from '../components/AppointmentModal'; // Modal bileşenini ekleyin
import './HomePage.css';

function HomePage() {
  const [psychologists, setPsychologists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal açık/kapalı state

  // Firestore'dan psikolog verilerini çekme
  useEffect(() => {
    const fetchPsychologists = async () => {
      try {
        const snapshot = await getDocs(collection(db, 'psychologists'));
        const psychologistsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setPsychologists(psychologistsData);
        setLoading(false);
      } catch (err) {
        setError("Veriler alınırken bir hata oluştu.");
        console.error("Psikolog verileri alınırken hata oluştu:", err);
        setLoading(false);
      }
    };
    fetchPsychologists();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="home-container">
      {/* Banner Bölümü */}
      <section className="hero-banner">
        <img
          src={`${process.env.PUBLIC_URL}/img/HomePageBanner.jpg`}
          alt="Banner"
          className="banner-image"
        />
        <div className="banner-text">
          
          <div className="banner-buttons">
            <button className="btn primary" onClick={openModal}>
              Randevu Al
            </button>
            <Link to="/kendini-test-et">
              <button className="btn secondary">Kendini Test Et</button>
            </Link>
          </div>
        </div>
      </section>

      {/* Bilgilendirme Kartları Bölümü */}
      <section className="info-cards">
        <div className="info-card">
          <h3>Psikolog Seçin</h3>
          <p>İhtiyacınıza uygun psikologları seçin ve size en uygun olanıyla terapi sürecine başlayın.</p>
        </div>
        <div className="info-card">
          <h3>Ödeme Yapın</h3>
          <p>Görüşme paketlerinizi satın alarak hızlı ve güvenli bir şekilde ödemenizi gerçekleştirin.</p>
        </div>
        <div className="info-card">
          <h3>Görüşme Yapın</h3>
          <p>Belirlenen zamanda online terapinizi gerçekleştirin, sağlıklı bir yaşamın kapısını aralayın.</p>
        </div>
      </section>

      {/* Psikolog Listesi Bölümü */}
      <section className="psychologists-section">
        <h2>Uzman Psikologlar</h2>
        
        <div className="psychologist-list">
          {loading ? (
            <p>Yükleniyor...</p>
          ) : error ? (
            <p>{error}</p>
          ) : psychologists.length > 0 ? (
            psychologists.map((psychologist) => (
              <PsychologistCard
                key={psychologist.id}
                imgSrc={psychologist.imgSrc || "https://via.placeholder.com/150"}
                name={psychologist.name}
                specialty={psychologist.specialties ? psychologist.specialties.join(', ') : ""}
                bio={psychologist.bio}
                price={psychologist.price}
              />
            ))
          ) : (
            <p>Uzman psikolog bulunamadı.</p>
          )}
        </div>
      </section>

      {/* Randevu Al Modal */}
      {isModalOpen && <AppointmentModal onClose={closeModal} />}
    </div>
  );
}

export default HomePage;
