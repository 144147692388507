import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import styles from './AppointmentsList.module.css';

const AppointmentsList = ({ psychologistName }) => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const appointmentsRef = collection(db, `appointments/${psychologistName}/appointments`);
        const appointmentsSnapshot = await getDocs(appointmentsRef);

        const appointmentsData = appointmentsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.name,
            email: data.email,
            phone: data.phone,
            notes: data.notes,
            type: data.type?.type, // type alt alanını alıyoruz
            price: data.type?.price, // price alt alanını alıyoruz
            start: data.day?.start,
            end: data.day?.end,
            status: data.status,
          };
        });

        setAppointments(appointmentsData);
      } catch (err) {
        console.error('Randevular yüklenirken hata:', err);
        setError('Randevular yüklenemedi.');
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [psychologistName]);

  const handleDetailsClick = (appointment) => {
    setSelectedAppointment(appointment);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? 'Geçersiz Tarih' : date.toLocaleDateString('tr-TR');
  };

  const formatTime = (start, end) => {
    const startTime = new Date(start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const endTime = new Date(end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return isNaN(new Date(start)) || isNaN(new Date(end)) ? 'Geçersiz Saat' : `${startTime} - ${endTime}`;
  };

  if (loading) return <p>Yükleniyor...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className={styles.appointmentsList}>
      <h2>Randevularım</h2>
      {appointments.length === 0 ? (
        <p>Henüz randevunuz yok.</p>
      ) : (
        <div className={styles.appointmentCards}>
          {appointments.map(appointment => (
            <div key={appointment.id} className={styles.appointmentCard}>
              <h3>{appointment.name || 'Müşteri İsmi Yok'}</h3>
              <p>Tarih: {formatDate(appointment.start)}</p>
              <p>Saat: {formatTime(appointment.start, appointment.end)}</p>
              <p>Seans Türü: {appointment.type || 'Seans Türü Yok'}</p>
              <p>Fiyat: {appointment.price ? `${appointment.price} TL` : 'Belirtilmemiş'}</p>
              <button onClick={() => handleDetailsClick(appointment)}>Detayları Göster</button>
            </div>
          ))}
        </div>
      )}

      {selectedAppointment && (
        <div className={styles.detailModal}>
          <h3>Randevu Detayları</h3>
          <p><strong>Müşteri İsmi:</strong> {selectedAppointment.name}</p>
          <p><strong>Müşteri Numarası:</strong> {selectedAppointment.phone}</p>
          <p><strong>Müşteri Email:</strong> {selectedAppointment.email}</p>
          <p><strong>Notlar:</strong> {selectedAppointment.notes || 'Not yok'}</p>
          <p><strong>Seans Türü:</strong> {selectedAppointment.type}</p>
          <p><strong>Fiyat:</strong> {selectedAppointment.price ? `${selectedAppointment.price} TL` : 'Belirtilmemiş'}</p>
          <p><strong>Tarih:</strong> {formatDate(selectedAppointment.start)}</p>
          <p><strong>Başlangıç:</strong> {new Date(selectedAppointment.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
          <p><strong>Bitiş:</strong> {new Date(selectedAppointment.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
          <p><strong>Durum:</strong> {selectedAppointment.status}</p>
          <button onClick={() => setSelectedAppointment(null)}>Kapat</button>
        </div>
      )}
    </div>
  );
};

export default AppointmentsList;
