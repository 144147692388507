import React from 'react';
import { FaInstagram, FaEnvelope, FaPhone, FaSpotify } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="contact-info">
        <div className="contact-item">
          <FaEnvelope className="contact-icon" />
          <a href="mailto:iletisim@uygunpsikolog.com" className="contact-link">iletisim@uygunpsikolog.com</a>
        </div>
        <div className="contact-item">
          <FaPhone className="contact-icon" />
          <a href="tel:+905521704440" className="contact-link">+90 552 170 44 40</a>
        </div>
        <div className="contact-item">
          <FaInstagram className="contact-icon" />
          <a href="https://www.instagram.com/uygunpsikologcom/profilecard/?igsh=c3kzeW0zNzVvd2E5" target="_blank" rel="noopener noreferrer" className="contact-link">
            @uygunpsikologcom
          </a>
        </div>
        <div className="contact-item">
          <FaSpotify className="contact-icon" />
          <a href="https://open.spotify.com/show/155EW5qg4txQjkYvZk57lm?si=ZyvkjRGWSCqgwmDw9o-Jyg" target="_blank" rel="noopener noreferrer" className="contact-link">
            Psikoloji Podcast
          </a>
        </div>
      </div>
      <div className="legal-links">
        <Link to="/kvkk" className="legal-link">KVKK Metni</Link>
        <Link to="/aydinlatma" className="legal-link">Aydınlatma Metni</Link>
        <Link to="/gizlilik-politikasi" className="legal-link">Gizlilik Politikası</Link>
        <Link to="/cancellation-and-refund-policy" className="legal-link">Kullanıcı İptal ve İade Sözleşmesi</Link>

      </div>
      <p>&copy; 2024 Uygun Psikolog. Tüm Hakları Saklıdır.</p>
      <img src="/img/logo_band_colored.svg" alt="Uygun Psikolog Logo" className="footer-logo" /> {/* Logo yolu doğrudan src ile eklendi */}
    </footer>
  );
}

export default Footer;
