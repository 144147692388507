import React, { useState } from 'react';
import './AvailabilityModal.css'; // Modal CSS dosyası

function AvailabilityModal({ selectedDate, onClose, onSave }) {
  const [eventTitle, setEventTitle] = useState('');
  const [selectedTimes, setSelectedTimes] = useState([]);

  // Saat değişikliklerini işleyen fonksiyon
  const handleTimeChange = (time) => {
    if (selectedTimes.includes(time)) {
      setSelectedTimes(selectedTimes.filter(t => t !== time)); // Eğer zaten seçilmişse çıkar
    } else {
      setSelectedTimes([...selectedTimes, time]); // Seçilen saate ekle
    }
  };

  // Formu gönderme işlemi
  const handleSubmit = (e) => {
    e.preventDefault();
    if (eventTitle.trim() === '') {
      alert('Etkinlik adı boş olamaz!'); // Kullanıcıya boş isim girişi uyarısı
      return;
    }
    onSave({ title: eventTitle, times: selectedTimes });
    setEventTitle(''); // Formu temizle
    setSelectedTimes([]); // Seçilen saatleri sıfırla
  };

  return (
    <div className="modal"> {/* Modal arka planı */}
      <div className="modal-content"> {/* Modal içeriği */}
        <span className="close" onClick={onClose}>&times;</span>
        <h2>{selectedDate} için Uygun Zamanlar</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Etkinlik Adı:
            <input
              type="text"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
              required
            />
          </label>
          <label>
            Seçilen Zamanlar:
            <div>
              {/* Saat seçenekleri */}
              {['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00'].map(time => (
                <div key={time}>
                  <input
                    type="checkbox"
                    value={time}
                    checked={selectedTimes.includes(time)}
                    onChange={() => handleTimeChange(time)}
                  />
                  {time}
                </div>
              ))}
            </div>
          </label>
          <button type="submit">Kaydet</button>
        </form>
      </div>
    </div>
  );
}

export default AvailabilityModal;
