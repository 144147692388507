import React, { useState, useEffect } from 'react';
import AppointmentTypeModal from './AppointmentTypeModal';
import AvailableDaysModal from './AvailableDaysModal';
import CustomerInfoForm from './CustomerInfoForm';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, where } from 'firebase/firestore';
import styles from './AppointmentFlow.module.css';

function AppointmentFlow({ name, psychologistId, onClose }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [psychologistName, setPsychologistName] = useState('');
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const [cardHolderName, setCardHolderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardMonth, setCardMonth] = useState('');
  const [cardYear, setCardYear] = useState('');
  const [cardCVC, setCardCVC] = useState('');

  useEffect(() => {
    const fetchAvailableDays = async () => {
      if (!psychologistId) {
        console.error('Psikolog kimliği tanımsız!');
        return;
      }

      try {
        const psychologistRef = collection(db, 'psychologists');
        const q = query(psychologistRef, where('name', '==', name));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const psychologistDoc = querySnapshot.docs[0];
          setPsychologistName(psychologistDoc.data().name);

          const eventsRef = collection(psychologistDoc.ref, 'events');
          const eventsSnapshot = await getDocs(eventsRef);

          const available = eventsSnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((event) => event.title === 'müsait');

          if (available.length > 0) {
            setAvailableDays(available);
          } else {
            setErrorMessage('Müsait gün bulunamadı.');
            setShowErrorModal(true);
          }
        } else {
          setErrorMessage('Psikolog bilgileri bulunamadı.');
          setShowErrorModal(true);
        }
      } catch (error) {
        console.error('Müsait günler çekilirken bir hata oluştu.', error);
      }
    };

    fetchAvailableDays();
  }, [psychologistId, name]);

  const handleNextStep = () => {
    if (currentStep === 1 && !selectedType) {
      setErrorMessage('Lütfen randevu türünü seçiniz.');
      return;
    }
    if (currentStep === 2 && !selectedDay) {
      setErrorMessage('Lütfen müsait bir gün seçiniz.');
      setShowErrorModal(true);
      return;
    }
    setErrorMessage('');
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = () => {
    setErrorMessage('');
    setCurrentStep((prev) => prev - 1);
  };

  const handleAppointmentTypeSelect = (type) => {
    setSelectedType(type);
    setErrorMessage('');
  };

  const handleDaySelect = (day) => {
    setSelectedDay(day);
    setErrorMessage('');
  };

  const handleCustomerInfoSubmit = (info) => {
    setCustomerInfo(info);
    handleNextStep();
  };

  const handlePayment = async (e) => {
    e.preventDefault();

    const price = selectedType?.price;

    if (!customerInfo || !customerInfo.name || !customerInfo.phone || !customerInfo.email) {
      setErrorMessage("Eksik müşteri bilgileri: name, phone veya email yok");
      return;
    }

    const paymentData = {
      psychologistName,
      selectedType,
      selectedDay,
      cardHolderName,
      cardNumber,
      cardMonth,
      cardYear,
      cardCVC,
      price,
      customerInfo,
    };

    try {
      const response = await fetch('https://iyzico-app-7edb1b7a26e0.herokuapp.com/api/initialize-payment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(paymentData),
      });

      const result = await response.json();
      if (result.success) {
        setPaymentSuccess(true);
      } else {
        setErrorMessage(result.message || 'Ödeme başarısız.');
      }
    } catch (error) {
      console.error('Ödeme işlemi hatası:', error);
      setErrorMessage('Ödeme işlemi sırasında bir hata oluştu.');
    }
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
    onClose();
  };

  const closeSuccessModal = () => {
    setPaymentSuccess(false);
    onClose();
  };

  const steps = ['Randevu Türü', 'Müsait Günler', 'Müşteri Bilgileri', 'Ödeme'];

  return (
    <div className={styles['appointment-flow-modal']}>
      <button className={styles['close-button']} onClick={onClose}>
        ×
      </button>

      <div className={styles['appointment-flow-content']}>
        <div className={styles['progress-bar']}>
          {steps.map((step, index) => (
            <div
              key={index}
              className={`${styles['progress-step']} ${index + 1 === currentStep ? styles['active'] : ''}`}
            >
              {step}
            </div>
          ))}
        </div>

        {errorMessage && <p className={styles['error-message']}>{errorMessage}</p>}

        {currentStep === 1 && (
          <AppointmentTypeModal onSelectType={handleAppointmentTypeSelect} onClose={onClose} />
        )}

        {currentStep === 2 && (
          <AvailableDaysModal availableDays={availableDays} onSelectDay={handleDaySelect} onClose={onClose} />
        )}

        {currentStep === 3 && (
          <CustomerInfoForm
            psychologistUid={psychologistId}
            selectedType={selectedType}
            selectedDay={selectedDay}
            onSubmit={handleCustomerInfoSubmit}
            onClose={onClose}
          />
        )}

{currentStep === 4 && (
  <div className={styles.paymentForm}>
    <h2>Ödeme Bilgilerini Girin</h2>
    <form onSubmit={handlePayment} className={styles.paymentFormContainer}>
      <div className={styles.formGroup}>
        <label htmlFor="cardHolderName">Kart Sahibi Adı</label>
        <input
          type="text"
          id="cardHolderName"
          value={cardHolderName}
          onChange={(e) => setCardHolderName(e.target.value)}
          required
          placeholder="Kart Sahibi Adı"
          className={styles.inputField}
        />
      </div>

      <div className={styles.formGroup}>
        <label htmlFor="cardNumber">Kart Numarası</label>
        <input
          type="text"
          id="cardNumber"
          value={cardNumber}
          onChange={(e) => setCardNumber(e.target.value)}
          required
          placeholder="1234 5678 9012 3456"
          className={styles.inputField}
        />
      </div>

      <div className={styles.expiryCvcContainer}>
        <div className={styles.formGroupSmall}>
          <label htmlFor="cardMonth">Ay</label>
          <input
            type="text"
            id="cardMonth"
            value={cardMonth}
            onChange={(e) => setCardMonth(e.target.value)}
            required
            placeholder="MM"
            className={styles.inputField}
          />
        </div>

        <div className={styles.formGroupSmall}>
          <label htmlFor="cardYear">Yıl</label>
          <input
            type="text"
            id="cardYear"
            value={cardYear}
            onChange={(e) => setCardYear(e.target.value)}
            required
            placeholder="YY"
            className={styles.inputField}
          />
        </div>

        <div className={styles.formGroupSmall}>
          <label htmlFor="cardCVC">CVC</label>
          <input
            type="text"
            id="cardCVC"
            value={cardCVC}
            onChange={(e) => setCardCVC(e.target.value)}
            required
            placeholder="123"
            className={styles.inputField}
          />
        </div>
      </div>

      <button type="submit" className={styles.submitButton}>Ödemeyi Tamamla</button>
    </form>
  </div>
)}


        <div className={styles['flow-controls']}>
          {currentStep > 1 && <button className={styles['back-button']} onClick={handlePrevStep}>Geri</button>}
          {currentStep < 4 && <button className={styles['next-button']} onClick={handleNextStep}>İleri</button>}
        </div>
      </div>

      {showErrorModal && (
        <div className={styles.errorModal}>
          <div className={styles.errorModalContent}>
            <h3>Hata</h3>
            <p>{errorMessage}</p>
            <button onClick={closeErrorModal}>Tamam</button>
          </div>
        </div>
      )}

      {paymentSuccess && (
        <>
          <div className={styles.successOverlay}></div>
          <div className={styles.successModal}>
            <div className={styles.successModalContent}>
              <h3>Ödeme Başarılı!</h3>
              <p>Ödemeniz başarıyla tamamlandı ve onay e-postası gönderildi.</p>
              <button onClick={closeSuccessModal}>Tamam</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AppointmentFlow;
