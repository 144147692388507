import React, { useState } from 'react';
import styles from './AppointmentFlow.module.css'; // CSS modülleri

function AvailableDaysModal({ availableDays, onSelectDay }) {
  const [selectedDay, setSelectedDay] = useState(null); // Seçilen günü tutmak için

  const handleDayClick = (day) => {
    setSelectedDay(day); // Seçilen günü güncelle
    onSelectDay(day); // Seçimi üst bileşene ilet
  };

  return (
    <div className={styles['appointment-flow-content']}>
      <h2>Müsait Günler</h2>
      <div className={styles['appointment-card-container']}>
        {availableDays.map((day) => (
          <div
            key={day.id}
            className={`${styles['appointment-card']} ${selectedDay === day ? styles.selected : ''}`}
            onClick={() => handleDayClick(day)}
          >
            <h3>{new Date(day.start).toLocaleDateString()}</h3>
            <p>
              {new Date(day.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} -{' '}
              {new Date(day.end).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AvailableDaysModal;
