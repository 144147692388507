import React, { useState } from 'react';

function ArizonaSexualExperiencesScale() {
  // Her sorunun cevabını tutan state
  const [answers, setAnswers] = useState(Array(5).fill(0)); 

  // Sorular ve seçenekler
  const questions = [
    {
      question: '1. Cinsel açıdan ne derece isteklisiniz?',
      options: [
        '1 = Oldukça istekli',
        '2 = Çok istekli',
        '3 = Biraz istekli',
        '4 = Biraz isteksiz',
        '5 = Çok isteksiz',
        '6 = Tamamen isteksiz'
      ]
    },
    {
      question: '2. Cinsel açıdan ne kadar kolay uyarılırsınız (tahrik olursunuz)?',
      options: [
        '1 = Oldukça kolay',
        '2 = Çok kolay',
        '3 = Biraz kolay',
        '4 = Biraz zor',
        '5 = Çok zor',
        '6 = Oldukça zor'
      ]
    },
    {
      question: '3. Penisiniz / cinsel organınız kolayca sertleşir ve bu sertliğini sürdürür mü?',
      options: [
        '1 = Oldukça kolay',
        '2 = Çok kolay',
        '3 = Biraz kolay',
        '4 = Biraz zor',
        '5 = Çok zor',
        '6 = Asla olmaz'
      ]
    },
    {
      question: '4. Ne kadar kolay boşalırsınız?',
      options: [
        '1 = Oldukça kolay',
        '2 = Çok kolay',
        '3 = Biraz kolay',
        '4 = Biraz zor',
        '5 = Çok zor',
        '6 = Asla boşalamam'
      ]
    },
    {
      question: '5. Boşalmanız tatmin edici midir?',
      options: [
        '1 = Oldukça tatmin edici',
        '2 = Çok tatmin edici',
        '3 = Biraz tatmin edici',
        '4 = Pek tatmin etmiyor',
        '5 = Çok tatmin etmiyor',
        '6 = Hiç boşalamam'
      ]
    }
  ];

  // Cevapları değiştiren fonksiyon
  const handleChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  // Toplam puanı hesaplayan fonksiyon
  const calculateTotalScore = () => {
    return answers.reduce((acc, curr) => acc + parseInt(curr), 0);
  };

  return (
    <div>
      <h1>Arizona Cinsel Yaşantılar Ölçeği (ACYÖ) - Erkek Formu</h1>
      {questions.map((questionObj, index) => (
        <div key={index}>
          <p>{questionObj.question}</p>
          <div>
            {questionObj.options.map((option, optionIndex) => (
              <label key={optionIndex} style={{ display: 'block', margin: '5px 0' }}>
                <input
                  type="radio"
                  name={`question-${index}`}
                  value={optionIndex + 1}
                  checked={answers[index] === optionIndex + 1}
                  onChange={() => handleChange(index, optionIndex + 1)}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      ))}
      <h2>Toplam Puan: {calculateTotalScore()}</h2>
    </div>
  );
}

export default ArizonaSexualExperiencesScale;
