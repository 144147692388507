// src/pages/AboutUsPage.js
import React from 'react';
import styles from './AboutUsPage.module.css'; // CSS modülünü ekleyin
import { FaEnvelope, FaPhone, FaInstagram, FaSpotify } from 'react-icons/fa'; // İkonlar

function AboutUsPage() {
    return (
        <div className={styles.aboutUsContainer}>
            <h1>Biz Kimiz?</h1>
            <p>Uygun Psikolog platformu, size en iyi online psikolog hizmetini sunmayı amaçlayan bir oluşumdur. Amacımız, psikologlara ulaşmayı daha kolay hale getirerek, ruh sağlığınızı korumanıza yardımcı olmaktır.</p>
            <p>Psikologlarımız, alanlarında uzman kişilerden oluşmaktadır ve size en uygun psikologları sunmak için sürekli olarak çalışmaktayız. Farklı uzmanlık alanlarına sahip psikologlarımız sayesinde, ihtiyacınıza en uygun desteği alabilirsiniz.</p>
            <p>Her zaman sizin yanınızdayız.</p>

            {/* İletişim Kartları */}
            <div className={styles.contactCards}>
                <div className={`${styles.contactCard} ${styles.mailCard}`}>
                    <FaEnvelope className={styles.contactIcon} />
                    <h3>Mail</h3>
                    <p><a href="mailto:iletisim@uygunpsikolog.com">iletisim@uygunpsikolog.com</a></p>
                </div>
                <div className={`${styles.contactCard} ${styles.phoneCard}`}>
                    <FaPhone className={styles.contactIcon} />
                    <h3>Telefon</h3>
                    <p><a href="tel:+905521704440">+90 552 170 44 40</a></p>
                </div>
                <div className={`${styles.contactCard} ${styles.instagramCard}`}>
                    <FaInstagram className={styles.contactIcon} />
                    <h3>Instagram</h3>
                    <p><a href="https://www.instagram.com/uygunpsikologcom/profilecard/?igsh=c3kzeW0zNzVvd2E5" target="_blank" rel="noopener noreferrer">instagram.com/uygunpsikolog</a></p>
                </div>
                <div className={`${styles.contactCard} ${styles.spotifyCard}`}>
                    <FaSpotify className={styles.contactIcon} />
                    <h3>Spotify</h3>
                    <p><a href="https://open.spotify.com/show/155EW5qg4txQjkYvZk57lm?si=ZyvkjRGWSCqgwmDw9o-Jyg" target="_blank" rel="noopener noreferrer">spotify.com/uygunpsikolog</a></p>
                </div>
            </div>
        </div>
    );
}

export default AboutUsPage;
